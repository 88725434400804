import type { HedgeAxis } from './scenariosHedgerApi';



import { hedgerApi } from '@/store/api/hedgerApi/hedgerApi.ts';
import type { StrictOmit } from '@/utils/libs/strictTypes.ts';


export type AdditionalField =
  | string
  | Record<string, string>;

type HedgeTimeType = 'ABSOLUTE' | 'RELATIVE';

type RecipientHedgeTime = { type: HedgeTimeType; time: string };

export interface StartEndTime {
  endTime: RecipientHedgeTime;
  startTime: RecipientHedgeTime;
}

export type AdditionalFields = Record<string, AdditionalField>;

export interface Recipient {
  recipientId: number;
  userId: string;
  team: string;
  recipientLabel: string;
  tradingToolTemplateId: number;
  hedgeAxis: HedgeAxis;
  additionalFields: AdditionalFields[];
  lastUpdateTime: string;
  sharedWithScenarios: number[];
}

export type GetRecipientsQueryResponse = { recipients: Recipient[] };

export type CreateRecipientData = StrictOmit<Recipient, 'recipientId'>;

export const recipientsHedgerApi = hedgerApi.injectEndpoints({
  endpoints: builder => ({
    getRecipients: builder.query<GetRecipientsQueryResponse, void>({
      providesTags: (result, error, arg, meta) => {
        if (result == null) {
          return ['Recipient'];
        }

        return [
          ...result.recipients.map(({ recipientId }) => ({
            type: 'Recipient' as const,
            id: recipientId,
          })),
          'Recipient',
        ];
      },
      query: () => `recipients/current-user`,
    }),
    createRecipient: builder.mutation<Recipient, CreateRecipientData>({
      query: arg => ({
        url: `recipients`,
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: [{ type: 'Recipient' }],
    }),
    updateRecipient: builder.mutation<Recipient, Recipient>({
      query: arg => ({
        url: `recipients`,
        method: 'PUT',
        body: arg,
      }),
      invalidatesTags: (result, error, arg, meta) => [{ type: 'Recipient', id: arg.recipientId }],
    }),
    deleteRecipient: builder.mutation<Recipient, Pick<Recipient, 'recipientId'>>({
      query: arg => ({
        url: `recipients/${arg.recipientId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg, meta) => [{ type: 'Recipient', id: arg.recipientId }],
    }),
    resetRecipients: builder.mutation<void, void>({
      query: () => ({
        url: `recipients/reset`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'Recipient' }],
    }),
  }),
});

export const {
  useGetRecipientsQuery,
  useUpdateRecipientMutation,
  useDeleteRecipientMutation,
  useCreateRecipientMutation,
  useResetRecipientsMutation,
} = recipientsHedgerApi;
