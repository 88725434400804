import { getRouteApi, useRouter } from '@tanstack/react-router';

import { useGetRefsTradingToolTemplatesQuery } from '@/store/api/hedgerApi/hedgerApi.ts';
import {
  useDeleteRecipientMutation,
  useGetRecipientsQuery,
  useUpdateRecipientMutation,
} from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import { ErrorPanel } from '@/components/common/bootstrap/ErrorPanel.tsx';
import { Loader } from '@/components/common/bootstrap/Loader.tsx';
import { FetchErrorView } from '@/components/common/utils/FetchErrorView.tsx';
import { EditRecipientFrame } from '@/components/hedger/manage/recipients/details/EditRecipientFrame.tsx';
import { safeParseNumber } from '@/utils/libs/safeParseNumber.ts';
import { isQueryHookResultReady } from '@/utils/rtk/predicates.ts';

const routeApi = getRouteApi('/hedger/recipients/edit/$recipientId');

export function EditRecipient(): JSX.Element {
  const { recipientId: recipientIdStr } = routeApi.useParams();
  const { navigate } = useRouter();

  const getRecipientsResult = useGetRecipientsQuery();
  const getRefsResult = useGetRefsTradingToolTemplatesQuery();

  const [deleteRecipient, deleteRecipientResult] = useDeleteRecipientMutation();
  const [updateRecipient, updateRecipientResult] = useUpdateRecipientMutation();

  if (
    updateRecipientResult.isLoading ||
    deleteRecipientResult.isLoading ||
    !isQueryHookResultReady(getRecipientsResult) ||
    !isQueryHookResultReady(getRefsResult)
  ) {
    return <Loader />;
  }

  if (getRecipientsResult.isError) {
    return <FetchErrorView error={getRecipientsResult.error} />;
  }
  if (getRefsResult.isError) {
    return <FetchErrorView error={getRefsResult.error} />;
  }

  const recipientToEdit = getRecipientsResult.data.recipients.find(
    recipient => recipient.recipientId === safeParseNumber(recipientIdStr),
  );
  if (recipientToEdit === undefined) {
    return <ErrorPanel> No Scenario with id {recipientIdStr} </ErrorPanel>;
  }

  const tradingToolTemplate = getRefsResult.data?.tradingToolTemplates.find(
    tradingToolTemplate =>
      tradingToolTemplate.tradingToolTemplateId === recipientToEdit?.tradingToolTemplateId,
  );
  if (tradingToolTemplate === undefined) {
    return (
      <ErrorPanel>The selected recipient has no appropriate Trading Tool Template </ErrorPanel>
    );
  }

  const currentRecipient =
    updateRecipientResult.isError && updateRecipientResult.originalArgs != null
      ? updateRecipientResult.originalArgs
      : recipientToEdit;

  function closeRecipient() {
    navigate({ to: '/hedger/recipients' });
  }

  const tradingToolTemplates = getRefsResult.data.tradingToolTemplates;
  return (
    <EditRecipientFrame
      recipient={currentRecipient}
      initTradingToolTemplate={tradingToolTemplate}
      tradingToolTemplates={tradingToolTemplates}
      deleteRecipient={deleteRecipient}
      updateRecipient={updateRecipient}
      closeRecipient={closeRecipient}
    />
  );
}
