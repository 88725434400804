import { useModal } from '@ebay/nice-modal-react';

import type { WebSocketConnection } from '@/core/webSocket/types.ts';
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import { saveWidgetConfigToSessionServiceThunk } from '@/store/slices/widget/widgetsSlice.ts';
import type { WidgetDef } from '@/store/slices/widget/widgetTypes.ts';
import { Catch } from '@/components/common/utils/Catch.tsx';
import type { WidgetParams } from '@/components/equityRisk/widgets/allWidgets.ts';
import { DndWidgets } from '@/components/equityRisk/widgets/DndWidgets.tsx';
import { EditWidgetModal } from '@/components/equityRisk/widgets/Modals/EditWidgetModal.tsx';
import {
  selectSelectedWidgetDefs,
  selectWidgetConfigFromWidget,
} from '@/components/equityRisk/widgets/widgetSelectors.ts';

export type WidgetDropdownChoice = 'Edit' | 'Remove';

interface WidgetPanelProps {
  webSocketConnection: WebSocketConnection;
  fullscreen?: boolean;
}
export function WidgetPanel({ webSocketConnection, fullscreen }: WidgetPanelProps) {
  const dispatch = useAppDispatch();

  const limit = useAppSelector(state => state.userPreferences.topPanelWidgetLimit);

  const selectedWidgetDefs = useAppSelector(selectSelectedWidgetDefs);
  const collapsed = useAppSelector(state => state.ui.areWidgetsCollapsed);
  const editWidgetModal = useModal(EditWidgetModal);

  function onSelectedWidgetDefs(nextSelectedWidgets: WidgetDef<any>[]) {
    dispatch(
      saveWidgetConfigToSessionServiceThunk(nextSelectedWidgets.map(selectWidgetConfigFromWidget)),
    );
  }

  function onWidgetDefEdit(widgetDefUpdated: WidgetDef<WidgetParams>) {
    const widgetConfigs = selectedWidgetDefs
      .map(widgetDef => (widgetDef.id === widgetDefUpdated.id ? widgetDefUpdated : widgetDef))
      .map(selectWidgetConfigFromWidget);

    dispatch(saveWidgetConfigToSessionServiceThunk(widgetConfigs));
  }
  function openEditWidgetModal(widgetDefId: string) {
    const widgetDef = selectedWidgetDefs.find(w => w.id === widgetDefId);
    if (widgetDef == null) {
      return;
    }
    editWidgetModal.show({
      onWidgetDefEdit,
      widgetDef,
    });
  }
  function onMenuClick(widgetId: string, item: WidgetDropdownChoice) {
    if (item === 'Edit') {
      openEditWidgetModal(widgetId);
    } else if (item === 'Remove') {
      onSelectedWidgetDefs(selectedWidgetDefs.filter(widget => widget.id !== widgetId));
    }
  }

  if (collapsed && !fullscreen) {
    return null;
  }

  const hasNoWidgetSelected = selectedWidgetDefs.length === 0;

  return (
    <Catch>
      <div className="widget-panel">
        {hasNoWidgetSelected ? (
          <div className="text-center text-secondary p-3 pb-5">
            <em className="icon icon-xl">search</em>
            <h4>No widget selected</h4>
            <p>To visualize widget, add them using the button on the top right.</p>
          </div>
        ) : (
          <DndWidgets
            hide={hasNoWidgetSelected}
            onMenuClick={onMenuClick}
            selectedWidgetDefs={
              fullscreen || limit === undefined
                ? selectedWidgetDefs
                : selectedWidgetDefs.slice(0, limit)
            }
            onSelectedWidgetDefsUpdate={onSelectedWidgetDefs}
            webSocketConnection={webSocketConnection}
            fullscreen={fullscreen}
          />
        )}
      </div>
    </Catch>
  );
}
