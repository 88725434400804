import { useMemo } from 'react';
import {
  DeleteCellRenderer,
  type DeleteCellRendererParams,
} from './cellRenderer/DeleteCellRenderer.tsx';
import {
  HedgePortfolioCellRenderer,
  type HedgdePortfolioCellRendererParams,
} from './cellRenderer/HedgePortfolioCellRenderer.tsx';
import {
  MapViewCellRenderer,
  type ViewTableCellRendererParams,
} from './cellRenderer/MapViewCellRenderer.tsx';
import type { ColDef, IRichCellEditorParams } from '@ag-grid-community/core';
import set from 'just-safe-set';

import type {
  KnownAdditionalFieldsName,
  TemplateAdditionalField,
} from '@/store/api/hedgerApi/hedgerApi.ts';
import type { AdditionalFields } from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import {
  MapEditCellRenderer,
  type MapEditCellRendererParams,
} from '@/components/hedger/manage/recipients/components/cellRenderer/MapEditCellRenderer.tsx';
import type { IntlMessages } from '@/types/intl';

type EditMode = 'Edit' | 'View';

export function useRecipientsColumnDefs(
  additionalFields: TemplateAdditionalField[],
  mode: EditMode,
): ColDef<AdditionalFields>[] {
  return useMemo(() => getRecipientColDefs(additionalFields, mode), [additionalFields, mode]);
}

function getRecipientColDefs(
  additionalFields: TemplateAdditionalField[],
  mode: string,
): ColDef<AdditionalFields>[] {
  const colDefs = additionalFields.toSorted(sortByName).map(additionalField => {
    switch (additionalField.type) {
      case 'Map<string,string>':
        return {
          valueFormatter: mode === 'Edit' ? ({ value }) => value : undefined,
          field: additionalField.name,
          headerName: additionalField.displayName,
          width: 200,
          cellRenderer: mode === 'Edit' ? MapEditCellRenderer : MapViewCellRenderer,
          cellRendererParams: {
            additionalField,
            headers: ['GOP', 'Account'],
          } as MapEditCellRendererParams | ViewTableCellRendererParams,
        } as ColDef<AdditionalFields>;

      case 'string':
      default:
        if (additionalField.name === 'hedgePortfolio') {
          return {
            valueFormatter: mode === 'Edit' ? ({ value }) => value : undefined,
            headerTooltip: additionalField.description,
            field: additionalField.name,
            headerName: additionalField.displayName,
            cellRenderer: HedgePortfolioCellRenderer,
            cellRendererParams: {
              additionalField,
              mode,
            } as HedgdePortfolioCellRendererParams,
          } as ColDef<AdditionalFields>;
        }

        if (
          additionalField.acceptedValues !== undefined &&
          additionalField.acceptedValues.length > 0 &&
          mode === 'Edit'
        ) {
          return {
            headerTooltip: additionalField.description,
            field: additionalField.name,
            headerName: additionalField.displayName,
            editable: true,
            cellEditor: 'agRichSelectCellEditor',
            valueSetter: ({ newValue, data }) => set(data, additionalField.name, newValue),
            cellEditorParams: {
              values: additionalField.acceptedValues,
              searchType: 'match',
              allowTyping: true,
              filterList: true,
              highlightMatch: true,
              valueListMaxHeight: 450,
            } as IRichCellEditorParams<AdditionalFields, string>,
          } as ColDef<AdditionalFields>;
        }
        return {
          headerTooltip: additionalField.description,
          editable: mode === 'Edit',
          field: additionalField.name,
          valueSetter: ({ newValue, data }) => set(data, additionalField.name, newValue),
          headerName: additionalField.displayName,
        } as ColDef<AdditionalFields>;
    }
  });
  if (mode === 'Edit') {
    colDefs.push({
      suppressHeaderMenuButton: true,
      width: 50,
      cellClass: 'p-0',
      cellRenderer: DeleteCellRenderer,
      cellRendererParams: {} as DeleteCellRendererParams,
    });
  }

  return colDefs;
}

const fieldOrder: KnownAdditionalFieldsName[] = [
  'hedgeAxisId',
  'accounts',
  'hedgePortfolio',
].reverse();

function sortByName(a: TemplateAdditionalField, b: TemplateAdditionalField) {
  return fieldOrder.indexOf(b.name) - fieldOrder.indexOf(a.name);
}

export function getTitleIdFromFieldName(
  name: KnownAdditionalFieldsName,
  mode: EditMode,
): IntlMessages | undefined {
  switch (name) {
    case 'accounts':
      return `Hedger.Recipients.${mode}Modal.Accounts`;
  }
}
