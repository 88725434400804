import { useId } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  useGetStormAnalysisQuery,
  useGetStormInstancesQuery,
  type StormDetails,
} from '@/store/api/hedgerApi/hedgerApi.ts';
import { Loader } from '@/components/common/bootstrap/Loader.tsx';
import { Select } from '@/components/common/bootstrap/Select.tsx';
import { FetchErrorView } from '@/components/common/utils/FetchErrorView.tsx';
import { NoAnalysis } from '@/components/hedger/execution/settings/NoAnalysis.tsx';
import { StormAnalysisTable } from '@/components/hedger/execution/storm/StormAnalysisTable.tsx';
import { toAnalysisRows } from '@/components/hedger/execution/storm/toAnalysisRow.ts';

interface InstancesSelectorProps {
  onChangeStormInstance: (stormName: string | undefined) => void;
  selectedStormName: string | undefined;
}

export function StormInstancesSelector({
  onChangeStormInstance,
  selectedStormName,
}: InstancesSelectorProps): JSX.Element | null {
  const {
    isLoading,
    data: stormInstancesData,
    isError,
    isUninitialized,
    error,
  } = useGetStormInstancesQuery();
  const id = useId();

  if (isLoading || isUninitialized) {
    return <Loader />;
  }

  if (isError) {
    return <FetchErrorView error={error} />;
  }

  const stormNames = stormInstancesData.stormNames;
  return (
    <div>
      <div className="form-group-deprecated">
        <label htmlFor={id} className="form-label">
          <FormattedMessage id="Hedging.Instance" />
        </label>
        {stormNames.length === 0 ? (
          <p className="text-secondary">
            <FormattedMessage id="HedgerApi.emptyStormInstances" />
          </p>
        ) : (
          <Select<string | undefined>
            id={id}
            itemsAsObjects={[undefined, ...stormNames]}
            onChange={onChangeStormInstance}
            selectedItem={selectedStormName}
          />
        )}
      </div>
    </div>
  );
}

interface AnalysisPanelProps {
  onSelectStormDetails: (analyses: StormDetails[]) => void;
  selectedStormName: string;
  selectedStormDetails: StormDetails[];
}

export function StormAnalysisSelector({
  onSelectStormDetails,
  selectedStormName,
  selectedStormDetails,
}: AnalysisPanelProps) {
  const {
    data: stormAnalysesData,
    isLoading,
    isUninitialized,
    isError,
    error,
  } = useGetStormAnalysisQuery({ stormName: selectedStormName });

  if (isLoading || isUninitialized) {
    return <Loader />;
  }

  if (isError) {
    return <FetchErrorView error={error} />;
  }

  const analysisRows = toAnalysisRows(stormAnalysesData.analyses);
  if (analysisRows.length === 0) {
    return <NoAnalysis title="HedgerApi.NoInstance.Title" message="HedgerApi.NoInstance.Message" />;
  }

  return (
    <div className="form-group-deprecated">
      <label>
        <FormattedMessage id="Hedging.Analysis" />
      </label>
      <StormAnalysisTable
        key={selectedStormName}
        riskAnalyses={analysisRows}
        onSelectStormDetails={onSelectStormDetails}
        selectedStormDetails={selectedStormDetails}
      />
    </div>
  );
}
