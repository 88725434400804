import type { HedgeAxis } from './scenariosHedgerApi';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { Order, OrderId, OrdersMetrics } from '@/store/api/hedgerApi/hedgerTypes.ts';
import type { ExclusionMode } from '@/store/slices/query/querySlice.ts';
import { setRequestHeaders } from '@/web/utils/webApi.ts';
import { HEDGER_CURRENT_ORDER_GROUP_SESSION_KEY } from '@/components/hedger/getOrderGroupIdFromSessionStorage.ts';
import { getConfig } from '@/utils/config/config.ts';
import { saveToStorage } from '@/utils/storage/storage.ts';

export function getOrderIdKey(orderId: OrderId) {
  return `${orderId.eliotId}_${orderId.productType}_${orderId.hedgeAxisId}_${orderId.hedgeAxis}`;
}

export const FIXED_CACHE_ORDERGROUP = 'current-order-group';

export interface MarketRef {
  marketId: string;
  currencyId: string;
}

export interface StormInfo {
  name: string;
  uri: string;
  machine: string;
  instanceId: string;
}

export interface RiskAnalysis {
  id: string;
  rootPortfolio: string;
  startTime: string;
  processName: string;
  analysisTags: string[];
  hasEliotRights: boolean;
  state: string;
  stateProgressInPercent: number;
  tenor: {
    currency: string;
    equityUnderlying: string;
  };
}

export interface StormAnalyses {
  stormName: string;
  analyses: StormAnalysis[];
}

export type StormAnalysis = { stormInfo: StormInfo; rtRiskAnalyses: RiskAnalysis[] };

export interface OrderGroup {
  userId: string;
  orderGroupId: number;
  scenarioId: number;
  generationTime: string;
  userRequest: OrderGroupRequest;
  orders: Order[];
  ordersMetrics: OrdersMetrics;
}

export interface OrderUpdateResponse {
  ordersMetrics: OrdersMetrics;
  unixTimestamp: number;
  order: Order;
}

export interface OrderUpdateRequest {
  orderGroupId: number;
  updates: OrderUpdate[];
}

export interface OrderUpdate {
  orderId: {
    eliotId: string;
    productType: string;
    hedgeAxis: HedgeAxis;
    hedgeAxisId: string;
  };
  isActive?: true;
  quantity?: 0;
  way?: 'BUY';
  hedgePercent?: 0;
}

export type OrderGroupSource = StormSource | GreatSource;

export type OrderGroupRequest = {
  scenarioId: number;
  source: OrderGroupSource;
};

export interface GeneratedOrderGroupFiles {
  files: GeneratedOrderGroupFile[];
}

export interface GenerateGroupFilesParams {
  orderGroupId: number;
}

export interface GeneratedOrderGroupFile {
  generatedFileId: number;
  generationTime: string;
  orderGroupId: number;
  userId: string;
  scenarioId: number;
  fileName: string;
  fileType: string;
  contentType: string;
}

export const analysisSourceValues = ['GREAT', 'STORM'] as const;
export type AnalysisSource = (typeof analysisSourceValues)[number];

export interface StormSource {
  sourceType: 'STORM';
  stormDetails: StormDetails[];
}

export interface GreatSource {
  sourceType: 'GREAT';
  greatDetails: GreatDetails;
}

export interface StormDetails {
  analysisId: string;
  uri: string;
  stormName: string;
  rootPortfolio: string;
}

export interface GreatDetails {
  selectedPerimeterList: string[];
  greatInstance: string;
  perimeterPresetName: string;
  exclusionMode: ExclusionMode;
}

export interface StormInstances {
  stormNames: string[];
}

export interface TradingToolTemplate {
  tradingToolTemplateId: number;
  transmissionLabel: string;
  orderTypeLabel: string;
  deskName: string;
  additionalFields?: TemplateAdditionalField[];
}

export type KnownAdditionalFieldsName =
  | 'hedgePortfolio'
  | 'accounts'
  | 'hedgeAxisId'
  | (string & {});

export interface TemplateAdditionalField {
  name: KnownAdditionalFieldsName;
  displayName: string;
  type: 'string' | 'Map<string,string>';
  description: string;
  example: string;
  acceptedValues?: string[];
}

export const hedgerApi = createApi({
  reducerPath: 'hedgerApi',
  tagTypes: ['StormAnalysis', 'OrderGroups', 'Scenario', 'Recipient'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig().apiUrl}/api/hedger`,
    timeout: 30000,
    prepareHeaders: headers => {
      return setRequestHeaders(headers);
    },
  }),
  endpoints: builder => ({
    getStormInstances: builder.query<StormInstances, void>({
      query: () => 'storm-instances',
    }),
    getStormAnalysis: builder.query<StormAnalyses, { stormName: string }>({
      query: ({ stormName }) => `storm-risk-analyses/${encodeURIComponent(stormName)}`,
    }),
    getOrderGroup: builder.query<OrderGroup, { orderGroupId: number }>({
      query: ({ orderGroupId }) => `order-groups/${orderGroupId}`,
      providesTags: (result, error, arg) => [
        { type: 'OrderGroups', id: arg.orderGroupId ?? 'LIST' },
      ],
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        const { data: orderGroup } = await queryFulfilled;
        saveToStorage(HEDGER_CURRENT_ORDER_GROUP_SESSION_KEY, orderGroup.orderGroupId);
      },
    }),
    postOrderGroups: builder.mutation<OrderGroup, OrderGroupRequest>({
      query: body => ({
        url: `order-groups`,
        method: 'POST',
        body,
      }),
    }),
    updateOrder: builder.mutation<OrderUpdateResponse, OrderUpdateRequest>({
      query: ({ orderGroupId, ...body }) => ({
        url: `order-groups/${orderGroupId}`,
        method: 'PATCH',
        body: body.updates,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'OrderGroups', id: arg.orderGroupId }],
    }),
    postOrderGroupGenerateFile: builder.mutation<
      GeneratedOrderGroupFiles,
      GenerateGroupFilesParams
    >({
      query: ({ orderGroupId }) => ({
        url: `order-groups/${orderGroupId}/generated-files`,
        method: 'POST',
      }),
    }),

    getRefsMarkets: builder.query<{ markets: MarketRef[] }, void>({
      query: () => `refs/markets`,
    }),
    getRefsTradingToolTemplates: builder.query<
      { tradingToolTemplates: TradingToolTemplate[] },
      void
    >({
      query: () => `refs/trading-tool-templates`,
    }),
  }),
});

export const {
  useGetStormInstancesQuery,
  useGetStormAnalysisQuery,
  useGetOrderGroupQuery,
  useGetRefsTradingToolTemplatesQuery,
  useGetRefsMarketsQuery,
  usePostOrderGroupsMutation,
  usePostOrderGroupGenerateFileMutation,
  useUpdateOrderMutation,
} = hedgerApi;
