import { createElement, type ComponentType, type ReactNode } from 'react';
import type {
  ColDef,
  GridOptions,
  ICellRendererParams,
  RowClassParams,
  RowStyle,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import get from 'just-safe-get';

export function AgGridLite<T extends Record<string, any>>(tableProps: GridOptions<T>): JSX.Element {
  const { columnDefs, rowData, getRowStyle } = tableProps;

  const columns = columnDefs ?? [];
  const data = rowData ?? [];

  return (
    <div style={{ maxHeight: '200px', overflow: 'auto' }}>
      <table className="table table-sm table-bordered">
        <thead>
          <tr>
            {columns.map(column => {
              const header = getHeader(column);
              return (
                <th className="nowrap" key={header} scope="col" title={column.headerTooltip}>
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {data.map((row, rowIndex) => {
            const style = getStyle(getRowStyle, row);
            return (
              <tr className="nowrap" style={style} key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <td className="align-content-center" key={colIndex}>
                    {getRowValue(row, column)}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function getStyle<T>(
  getRowStyle: { (params: RowClassParams<T, any>): RowStyle | undefined } | undefined,
  row: T,
) {
  return getRowStyle !== undefined
    ? getRowStyle({ data: row } as RowClassParams<T, any>)
    : undefined;
}

function getHeader(column: ColDef) {
  return column.headerName ?? column.field;
}

function getRowValue<T extends Record<string, any>>(row: T, column: ColDef<T>): ReactNode {
  const { valueFormatter, field, cellRenderer, cellRendererParams } = column;
  const value = field !== undefined ? get(row, field) : undefined;
  const valueFormatted =
    valueFormatter !== undefined && typeof valueFormatter === 'function'
      ? valueFormatter({ value, data: row } as ValueFormatterParams<T>)
      : value?.toString();

  if (cellRenderer !== undefined && typeof cellRenderer === 'function') {
    const Compo: ComponentType = cellRenderer;
    return createElement<any>(Compo, {
      ...cellRendererParams,
      value,
      data: row,
      valueFormatted,
    } as ICellRendererParams<T>);
  }

  return valueFormatted;
}
