import type { VisualizationPreset } from '@/store/slices/prefs/visualizationPresetsSlice.ts';

export const pnlDefaultPresets: VisualizationPreset[] = [
  {
    columnGroupState: [
      {
        groupId: 'DeltaCurrencyIntraday_group',
        open: true,
      },
    ],
    columnState: [
      {
        sort: null,
        width: 250,
        colId: 'Hierarchy',
        hide: false,
        pinned: 'left',
      },
      {
        sort: null,
        width: 120,
        colId: 'Currency',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'UnderlyerSpotRT',
        hide: false,
        pinned: 'left',
      },
      {
        sort: null,
        width: 120,
        colId: 'UnderlyerSpotRisk',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'Spot0',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'UnderlyerSpotRTMove',
        hide: false,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'DeltaCurrencyIntraday',
        hide: false,
        pinned: null,
      },
      {
        sort: null,
        width: 155,
        colId: 'DeltaOpenRTIntraday',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'NDeltaOpenMove',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 140,
        colId: 'XGamma',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 140,
        colId: 'XGammaDiag',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'DeltaJumpOpenCurrency',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'DeltaJumpOpen',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'NDeltaOpenMoveRTDisplayCurrency',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 140,
        colId: 'CrossGammaCrossOpenRTDisplayCurrency',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 140,
        colId: 'CrossGammaDiagOpenRTDisplayCurrency',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 140,
        colId: 'DeltaExcludingFixingOpenRTDisplayCurrency',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 130,
        colId: 'DeltaOpenIntraday',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 130,
        colId: 'DeltaCurrencyOfficial',
        hide: false,
        pinned: null,
      },
      {
        sort: null,
        width: 160,
        colId: 'DeltaOpenOfficial',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 130,
        colId: 'DeltaDailyCurrency',
        hide: false,
        pinned: null,
      },
      {
        sort: null,
        width: 130,
        colId: 'DeltaDraftDailyCurrency',
        hide: false,
        pinned: null,
      },
      {
        sort: null,
        width: 170,
        colId: 'DeltaDaily',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'SpotSource',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'Gamma',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'GammaDaily',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'GammaOpen',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'Vega',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'VegaDaily',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'VegaOpen',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'Theta',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'ThetaDaily',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'ThetaOpen',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'PnlTotalE',
        hide: false,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'Pnl0E',
        hide: false,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'PnlThetaE',
        hide: false,
        pinned: null,
      },
      {
        colId: 'PnlGammaE',
        hide: true,
        pinned: null,
        sort: null,
        width: 120,
      },
      {
        colId: 'PnlDeltaDayE',
        hide: true,
        pinned: null,
        sort: null,
        width: 120,
      },
      {
        sort: null,
        width: 120,
        colId: 'SpotUpdate',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'CrossGammaTransDisplayCurrency',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'CrossGammaTransOpenDisplayCurrency',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'CrossGammaTransDailyDisplayCurrency',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'ProductQuantity',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'ProductQuantityOpen',
        hide: true,
        pinned: null,
      },
      {
        sort: null,
        width: 120,
        colId: 'ProductQuantityDaily',
        hide: true,
        pinned: null,
      },
    ],
    name: 'PNL - Default',
    team: 'DEFAULT',
    hierarchies: ['ProfitCenterDesc', 'GOP', 'PortFolio', 'UnderlyerCode'],
    filtersEnabled: true,
  },
];
