import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import type { IntlMessages } from '@/types/intl';
import { objectKeys } from '@/utils/libs/entries.ts';

export type TableModalProps = {
  name: string;
  titleId?: IntlMessages;
  data: Record<string, string>;
};

export const ViewTableModal = NiceModal.create(function TableModal({
  data,
  titleId,
}: TableModalProps) {
  const modal = useModal();

  return (
    <BootstrapModal
      style={{ minHeight: '28em', minWidth: '51em' }}
      footer={
        <Button className="ps-3 pe-3 pt-2 pb-2" variant="primary" onClick={() => modal.remove()}>
          Close
        </Button>
      }
      titleId={titleId}
    >
      <div className="d-flex flex-column gap-4">
        <Table data={data} />
      </div>
    </BootstrapModal>
  );
});

interface TableProps {
  data: Record<string, string>;
  title?: string;
}

function Table({ data, title }: TableProps) {
  const keys = objectKeys(data);

  return (
    <div className="d-flex flex-column gap-2">
      {title != null ? <h6> {title} </h6> : null}
      <table className="table table-sm table-bordered">
        <tbody>
          {keys.map(key => (
            <tr key={key}>
              <td>{key}</td>
              <td>{`${data[key]}`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
