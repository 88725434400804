import type { Hierarchy } from '@/core/hierachies.ts';
import type { MeasureId } from '@/core/measures.ts';
import type { ExclusionMode } from '@/store/slices/query/querySlice.ts';
import type { WidgetConfig, WidgetConfigParams } from '@/store/slices/widget/widgetsSlice.ts';
import type { CubeMode } from '@/types/AppConfig.ts';

export type WidgetType = (typeof WidgetTypes)[number];
export const WidgetTypes = [
  'topDelta',
  'multiAxis',
  'TopGamma',
  'bottomDelta',
  'topVegaDay',
  'topGammaDay',
  'topDeltaAbsolute',
] as const;
export const WidgetCategories = ['General', 'Vega', 'Delta', 'Gamma', 'MultiAxis'] as const;
export const widgetDescription = 'Highest gamma real with a spot var above a threshold';
export type WidgetCategory = (typeof WidgetCategories)[number];

export interface WidgetHierarchyColumn {
  type: 'hierarchy';
  name: Hierarchy;
}

export interface WidgetMeasureColumn {
  type: 'measure';
  measureId: MeasureId;
}

export interface WidgetMeasureCrossHierarchy {
  type: 'measureCrossHierarchy';
  name: string;
  measureId: MeasureId;
  rowHierarchy: Hierarchy;
  columnHierarchy: Hierarchy;
}

export interface WidgetQueryParams {
  perimeters: string[];
  cubeName: string;
  cubeMode: CubeMode;
  valueDate: string;
  exclusionMode: ExclusionMode;
}
export type WidgetColumn =
  | WidgetHierarchyColumn
  | WidgetMeasureColumn
  | WidgetMeasureCrossHierarchy;

export type WidgetColumnMultiAxis = WidgetHierarchyColumn | WidgetMeasureCrossHierarchy;
export interface WidgetDef<Params extends WidgetConfigParams> extends WidgetConfig {
  description?: string;
  category: WidgetCategory;
  title: string;
  columns?: WidgetColumn[];
  getQuery: (widgetQueryParams: WidgetQueryParams, params: WidgetConfigParams) => string;
  params: Params;
  size: { width: number; height: number };
}

interface WidgetDefMonoAxis<Params extends WidgetConfigParams> extends WidgetDef<Params> {
  category: Exclude<WidgetCategory, 'MultiAxis'>;
  columns: (WidgetHierarchyColumn | WidgetMeasureColumn)[];
}
interface WidgetDefMultiAxis<Params extends WidgetConfigParams> extends WidgetDef<Params> {
  category: Extract<WidgetCategory, 'MultiAxis'>;
  columns: (WidgetHierarchyColumn | WidgetMeasureCrossHierarchy)[];
}

// export type WidgetDef<Params extends WidgetConfigParams> =
//   | WidgetDefMultiAxis<Params>
//   | WidgetDefMonoAxis<Params>;

export function isWidgetDefMonoAxis<T extends WidgetConfigParams>(
  widgetDef: WidgetDef<T>,
): widgetDef is WidgetDefMonoAxis<any> {
  return widgetDef.category !== 'MultiAxis';
}
