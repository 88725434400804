import { useRouter, useSearch } from '@tanstack/react-router';

import {
  useGetRefsTradingToolTemplatesQuery,
  type TradingToolTemplate,
} from '@/store/api/hedgerApi/hedgerApi.ts';
import {
  useCreateRecipientMutation,
  useGetRecipientsQuery,
  type CreateRecipientData,
  type Recipient,
} from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import { useAppSelector } from '@/store/hooks.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import { ErrorPanel } from '@/components/common/bootstrap/ErrorPanel.tsx';
import { Loader } from '@/components/common/bootstrap/Loader.tsx';
import { FetchErrorView } from '@/components/common/utils/FetchErrorView.tsx';
import { CreateRecipientFrame } from '@/components/hedger/manage/recipients/details/CreateRecipientFrame.tsx';
import { isQueryHookResultReady } from '@/utils/rtk/predicates.ts';
import { getUserInfo } from '@/utils/sgwt/userPermission.ts';

export function CreateRecipient(): JSX.Element {
  const teams = useAppSelector(userSlice.selectors.teams);

  const [createRecipient, { isLoading, isError, originalArgs }] = useCreateRecipientMutation();
  const getRecipientsResult = useGetRecipientsQuery();
  const getRefsResult = useGetRefsTradingToolTemplatesQuery();

  const { navigate } = useRouter();
  const { cloneId } = useSearch({ from: '/hedger/recipients/create' });

  function close() {
    return navigate({ to: '/hedger/recipients' });
  }

  if (
    isLoading ||
    !isQueryHookResultReady(getRefsResult) ||
    !isQueryHookResultReady(getRecipientsResult)
  ) {
    return <Loader />;
  }

  if (getRefsResult.isError) {
    return <FetchErrorView error={getRefsResult.error} />;
  }

  if (getRecipientsResult.isError) {
    return <FetchErrorView error={getRecipientsResult.error} />;
  }

  const recipients = getRecipientsResult.data.recipients;
  const recipientToClone = recipients.find(({ recipientId }) => recipientId === cloneId);
  if (cloneId !== undefined && recipientToClone === undefined) {
    return (
      <ErrorPanel>
        No recipient of id <span className="fw-semibold">{cloneId}</span> .
      </ErrorPanel>
    );
  }

  const tradingToolTemplates = getRefsResult.data?.tradingToolTemplates;

  if (tradingToolTemplates === undefined || tradingToolTemplates.length === 0) {
    return <ErrorPanel> No trading tool templates available</ErrorPanel>;
  }

  const recipientInit: CreateRecipientData =
    isError && originalArgs !== undefined
      ? originalArgs
      : getRecipientInit(tradingToolTemplates, teams, recipientToClone);

  return (
    <CreateRecipientFrame
      recipientInit={recipientInit}
      createRecipient={createRecipient}
      tradingToolTemplates={tradingToolTemplates}
      close={close}
    />
  );
}

function getRecipientInit(
  tradingToolTemplates: TradingToolTemplate[],
  teams: string[],
  recipientInit?: Recipient,
): Omit<Recipient, 'recipientId'> {
  const baseRecipientInit: Omit<Recipient, 'recipientId'> = {
    sharedWithScenarios: [],
    lastUpdateTime: '',
    team: teams[0],
    userId: getUserInfo()?.sesame_id || '',
    hedgeAxis: 'MarketCode',
    additionalFields: [
      {
        hedgePortfolio: '*',
        marketLetter: 'foo',
        accounts: {
          '*': 'foo',
        },
        startTime: {},
        endTime: {},
      },
    ],
    tradingToolTemplateId: tradingToolTemplates[0].tradingToolTemplateId,
    recipientLabel: '',
  };
  return recipientInit === undefined
    ? baseRecipientInit
    : {
        ...baseRecipientInit,
        ...recipientInit,
        userId: getUserInfo()?.sesame_id || '',
        recipientLabel: '',
        team: (teams.includes(recipientInit.team) ? recipientInit.team : teams[0]) ?? '',
      };
}
