import { useState } from 'react';
import { getTitleIdFromFieldName } from '../useRecipientsColDefs.tsx';
import type { CustomCellRendererProps } from '@ag-grid-community/react';
import { useModal } from '@ebay/nice-modal-react';
import { FormattedMessage } from 'react-intl';

import type { TemplateAdditionalField } from '@/store/api/hedgerApi/hedgerApi.ts';
import type { AdditionalFields } from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { Tooltip } from '@/components/common/bootstrap/Tooltip.tsx';
import {
  EditTableModal,
  type EditTableModalResult,
} from '@/components/hedger/manage/recipients/details/EditTableModal.tsx';
import { objectKeys } from '@/utils/libs/entries.ts';

export interface MapEditCellRendererParams
  extends CustomCellRendererProps<AdditionalFields, Record<string, string>> {
  additionalField: TemplateAdditionalField;
  headers: [string, string];
}

type EditMode = 'single' | 'multi';

export function MapEditCellRenderer(params: MapEditCellRendererParams) {
  const tableData = params.value!;
  const data = params.data!;
  const headers = params.headers;
  const editTableModal = useModal(EditTableModal);
  const actionLabel = getTitleIdFromFieldName(params.additionalField.name, 'Edit');

  const keys = objectKeys(tableData);
  const isSingle = keys.length === 1 && keys[0] === '*';

  const [mode, setMode] = useState<EditMode>(isSingle ? 'single' : 'multi');
  const nextMode = mode === 'single' ? 'multi' : 'single';

  function handleSwitchMode(): void {
    setMode(mode => (mode === 'single' ? 'multi' : 'single'));
  }

  function setCellValue(newTableData: EditTableModalResult) {
    data[params.additionalField.name] = newTableData;
    params.api.refreshCells({
      columns: [params.additionalField.name],
      rowNodes: [params.node],
      force: true,
    });
  }

  function handleInputChange(newValue: string): void {
    setCellValue({ '*': newValue });
  }

  async function handleClick() {
    const newTableData = (await editTableModal.show({
      data: tableData,
      titleId: actionLabel,
      headers,
    })) as EditTableModalResult;
    setCellValue(newTableData);
  }
  if (mode === 'single') {
    return (
      <div className="flex-center h-100 w-100">
        <input
          className="form-control form-control-sm"
          type="text"
          value={tableData[keys[0]]}
          onChange={e => handleInputChange(e.target.value)}
          placeholder="Type account number"
        ></input>

        <Tooltip contents={`Switch to ${nextMode} account`} placement="right">
          <Button onClick={handleSwitchMode} icon flat>
            <i className="icon icon-sm">swap_horizontal_circle</i>
          </Button>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className="flex-center flex-between" style={{ height: 28 }}>
      <Button size="sm" discreet variant="info" onClick={handleClick}>
        <FormattedMessage id={actionLabel} />
      </Button>

      <Tooltip contents={`Switch to ${nextMode} account`} placement="right">
        <Button onClick={handleSwitchMode} icon flat>
          <i className="icon icon-sm">swap_horizontal_circle</i>
        </Button>
      </Tooltip>
    </div>
  );
}
