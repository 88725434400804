import { hedgerApi, type OrderUpdate } from '@/store/api/hedgerApi/hedgerApi.ts';
import { addErrorToastThunk } from '@/store/slices/ui/uiSlice.ts';
import { store } from '@/store/store.ts';
import { getOrderGroupIdFromSessionStorage } from '@/components/hedger/getOrderGroupIdFromSessionStorage.ts';


export async function disableAllActiveOrders() {
  const orderGroupId = getOrderGroupIdFromSessionStorage();
  if (orderGroupId === undefined) {
    store.dispatch(addErrorToastThunk('Disabling active orders failed.', 'Error: id not found'));
    return;
  }
  const { data: orderGroup } = hedgerApi.endpoints?.getOrderGroup.select({ orderGroupId })(
    store.getState(),
  );
  if (orderGroup === undefined) {
    store.dispatch(addErrorToastThunk('Disabling active orders failed.'));
    return;
  }
  const updates: OrderUpdate[] = orderGroup.orders
    .filter(order => order.active)
    .map(({ orderId }) => {
      return {
        orderId,
        active: false,
      };
    });
  if (updates.length === 0) {
    return;
  }
  store.dispatch(hedgerApi.endpoints?.updateOrder.initiate({ orderGroupId, updates }));
}