import { useEffect, useState } from 'react';
import type { CustomCellRendererProps } from '@ag-grid-community/react';

import type { TemplateAdditionalField } from '@/store/api/hedgerApi/hedgerApi.ts';
import type { AdditionalFields } from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { Tooltip } from '@/components/common/bootstrap/Tooltip.tsx';

export interface HedgdePortfolioCellRendererParams
  extends CustomCellRendererProps<AdditionalFields, string> {
  additionalField: TemplateAdditionalField;
  mode: 'Edit' | 'View';
}

export function HedgePortfolioCellRenderer(params: HedgdePortfolioCellRendererParams) {
  const value = params.value ?? undefined;
  const data = params.data!;
  const additionalField = params.additionalField;
  const isEditable = params.mode === 'Edit';

  const mode: 'inItself' | 'manual' = value === '*' || value === undefined ? 'inItself' : 'manual';
  const nextMode = mode === 'inItself' ? 'manual' : 'inItself';

  function handleSwitchMode(): void {
    if (nextMode === 'inItself') {
      setCellValue('*');
    } else {
      setCellValue('');
    }
  }

  function setCellValue(newValue: string) {
    data[additionalField.name] = newValue;
    params.api.refreshCells({
      columns: [additionalField.name],
      rowNodes: [params.node],
      force: true,
    });
  }

  function handleInputChange(newValue: string): void {
    setCellValue(newValue);
  }

  if (mode === 'inItself') {
    return (
      <div className="d-flex flex-between align-items-center">
        <div className="badge bg-alt-lvl4 badge-md badge-prepend-square">In itself</div>
        {isEditable && (
          <Tooltip contents={`Switch to ${nextMode} account`} placement="right">
            <Button onClick={handleSwitchMode} icon flat>
              <i className="icon icon-sm">swap_horizontal_circle</i>
            </Button>
          </Tooltip>
        )}
      </div>
    );
  }

  return (
    <div className="flex-center flex-between" style={{ height: 28 }}>
      {isEditable ? (
        <input
          className="form-control form-control-sm"
          type="text"
          value={value}
          onChange={e => handleInputChange(e.target.value)}
          placeholder="Type account number"
        ></input>
      ) : (
        <>{value}</>
      )}

      {isEditable && (
        <Tooltip contents={`Switch to ${nextMode} account`} placement="right">
          <Button onClick={handleSwitchMode} icon flat>
            <i className="icon icon-sm">swap_horizontal_circle</i>
          </Button>
        </Tooltip>
      )}
    </div>
  );
}
