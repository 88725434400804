import type { ColDef } from '@ag-grid-community/core';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useRouter } from '@tanstack/react-router';
import { FormattedMessage } from 'react-intl';
import { asSequence } from 'sequency';

import {
  useGetRefsTradingToolTemplatesQuery,
  type TradingToolTemplate,
} from '@/store/api/hedgerApi/hedgerApi.ts';
import {
  useGetRecipientsQuery,
  type AdditionalFields,
  type Recipient,
} from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import { useAppSelector } from '@/store/hooks.ts';
import { userSlice } from '@/store/slices/user/userSlice.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { Loader } from '@/components/common/bootstrap/Loader.tsx';
import { FetchErrorView } from '@/components/common/utils/FetchErrorView.tsx';
import { AgGridLite } from '@/components/hedger/manage/common/AgGridLite.tsx';
import { ViewHeader, type DataItem } from '@/components/hedger/manage/common/ViewHeader.tsx';
import { useRecipientsColumnDefs } from '@/components/hedger/manage/recipients/components/useRecipientsColDefs.tsx';
import { useFormatHedgeAxis } from '@/components/hedger/manage/scenarios/components/hedgeAxis.ts';

interface ViewRecipientFrameProps {
  recipient: Recipient;
  tradingToolTemplate: TradingToolTemplate;
}

export function ViewRecipients(): JSX.Element | null {
  const getRecipientsQueryHookResult = useGetRecipientsQuery();
  const getRefsTradingToolTemplatesResult = useGetRefsTradingToolTemplatesQuery();

  if (
    getRefsTradingToolTemplatesResult.isLoading ||
    getRefsTradingToolTemplatesResult.isUninitialized ||
    getRecipientsQueryHookResult.isLoading ||
    getRecipientsQueryHookResult.isUninitialized
  ) {
    return <Loader />;
  }

  if (getRecipientsQueryHookResult.isError) {
    return <FetchErrorView error={getRecipientsQueryHookResult.error} />;
  }
  if (getRefsTradingToolTemplatesResult.isError) {
    return <FetchErrorView error={getRefsTradingToolTemplatesResult.error} />;
  }

  const recipients = getRecipientsQueryHookResult.data.recipients;
  const tradingToolTemplates = getRefsTradingToolTemplatesResult.data.tradingToolTemplates;

  const Recipients = asSequence(recipients)
    .sortedBy(recipient => recipient.recipientId)
    .mapNotNull(recipient => {
      const tradingToolTemplate = tradingToolTemplates.find(
        tradingToolTemplate =>
          tradingToolTemplate.tradingToolTemplateId === recipient.tradingToolTemplateId,
      );
      if (tradingToolTemplate === undefined) {
        return null;
      }

      return (
        <ViewRecipient
          key={recipient.recipientId}
          recipient={recipient}
          tradingToolTemplate={tradingToolTemplate}
        />
      );
    })
    .toArray();

  return (
    <div className="d-flex flex-column mx-auto w-75 gap-3" style={{ minWidth: '60rem' }}>
      {Recipients}
    </div>
  );
}

function ViewRecipient(viewRecipientFrameProps: ViewRecipientFrameProps): JSX.Element | null {
  const { recipient, tradingToolTemplate } = viewRecipientFrameProps;
  const { trackEvent } = useMatomo();

  const { navigate } = useRouter();
  const hasTeams = useAppSelector(userSlice.selectors.hasAnyTeam);

  const formatHedgeAxis = useFormatHedgeAxis();

  const recipientDataItem: DataItem[] = [
    { title: 'Owner team', data: recipient.team },
    { title: 'Trading tool', data: tradingToolTemplate.transmissionLabel },
    { title: 'Order type', data: tradingToolTemplate.orderTypeLabel },
    { title: 'Axis', data: formatHedgeAxis(recipient.hedgeAxis) },
  ];

  function onEditClick() {
    return navigate({
      to: '/hedger/recipients/edit/$recipientId',
      params: { recipientId: recipient.recipientId.toString() },
    });
  }

  function onCloneClick() {
    trackEvent({
      category: 'Hedger',
      action: 'Clone recipient',
      name: `Cloned recipient Id : ${recipient.recipientId}`,
    });
    return navigate({
      to: '/hedger/recipients/create',
      search: { cloneId: recipient.recipientId },
    });
  }

  const columns: ColDef<AdditionalFields>[] = useRecipientsColumnDefs(
    tradingToolTemplate.additionalFields ?? [],
    'View',
  );

  const rows: AdditionalFields[] = recipient.additionalFields;

  return (
    <div className="d-flex flex-column flex-grow-1 bg-lvl2 gap-3 p-3">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-3">
          <h4>{recipient.recipientLabel}</h4>
          <span className="badge rounded-pill badge-discreet-info">
            Used in {recipient.sharedWithScenarios?.length} scenarios
          </span>
        </div>
        <div className="d-flex gap-2">
          <Button size="lg" className="btn-icon-end" onClick={onCloneClick} disabled={!hasTeams}>
            <FormattedMessage id="Hedger.ScenariosModal.Clone" />
            <em className="icon">content_copy </em>
          </Button>
          <Button size="lg" className="btn-icon-end" onClick={onEditClick} disabled={!hasTeams}>
            <FormattedMessage id="Hedger.ScenariosModal.Edit" />
            <em className="icon">edit </em>
          </Button>
        </div>
      </div>
      <ViewHeader items={recipientDataItem} />
      <AgGridLite columnDefs={columns} rowData={rows} />
    </div>
  );
}
