import { useId } from 'react';
import { FormattedMessage } from 'react-intl';

import { analysisSourceValues, type AnalysisSource } from '@/store/api/hedgerApi/hedgerApi.ts';

interface SourceSelectorProps {
  onClick: (source: AnalysisSource) => void;
  activeSource: AnalysisSource;
}

export function SourceSelector({ onClick, activeSource }: SourceSelectorProps): JSX.Element {
  const id = useId();
  return (
    <>
      <div className="form-group-deprecated">
        <label className="form-label" htmlFor={id}>
          <FormattedMessage id="Hedging.Source" />
        </label>
        <div className="btn-group btn-group-toggle d-block">
          {analysisSourceValues.map((source, index) => {
            return (
              <button
                id={id}
                key={index}
                onClick={() => onClick(source)}
                type="button"
                className={`btn btn-toggle-primary ${activeSource === source && 'active'}`}
              >
                {source}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
}
