import { useRef } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type { GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';

import type { TradingToolTemplate } from '@/store/api/hedgerApi/hedgerApi.ts';
import type { AdditionalFields } from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import type { AxisConfig } from '@/store/api/hedgerApi/scenariosHedgerApi.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { useRecipientsColumnDefs } from '@/components/hedger/manage/recipients/components/useRecipientsColDefs.tsx';

// export const EditRecipientGrid = memo(EditRecipientAdditionalFieldsGrid);

export interface EditAdditionalFieldsGrid {
  additionalFields: AdditionalFields[];
  tradingToolTemplate: TradingToolTemplate;
}

export function EditRecipientGrid(props: EditAdditionalFieldsGrid): JSX.Element {
  const gridApi = useRef<GridApi>();
  const { additionalFields, tradingToolTemplate } = props;

  const colDefs = useRecipientsColumnDefs(tradingToolTemplate.additionalFields ?? [], 'Edit');

  function onGridReady({ api }: GridReadyEvent) {
    api.sizeColumnsToFit();
    gridApi.current = api;
  }

  function handleAddRow() {
    const newData =
      tradingToolTemplate.additionalFields?.map(field => {
        const key = field.name;
        const value = field.type === 'Map<string,string>' ? {} : '';
        return [key, value] as [string, string];
      }) ?? [];
    additionalFields.push(Object.fromEntries(newData));
    gridApi.current?.setGridOption('rowData', additionalFields);
    gridApi.current?.ensureIndexVisible(additionalFields.length - 1);
  }

  return (
    <div
      className="d-flex flex-column flex-grow-1 ag-theme-alpine ag-theme-era ag-theme-era-oneline"
      style={{ height: '5rem' }}
    >
      <div className="d-flex justify-content-end pb-2">
        <Button variant="primary" discreet onClick={handleAddRow}>
          Add new line
        </Button>
      </div>

      <AgGridReact
        onGridReady={onGridReady}
        tooltipShowDelay={1000}
        modules={[ClientSideRowModelModule, RangeSelectionModule, RichSelectModule]}
        singleClickEdit
        enableRangeSelection
        rowData={additionalFields}
        columnDefs={colDefs}
      />
    </div>
  );
}
