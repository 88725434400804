import { useState } from 'react';
import omit from 'just-omit';
import { FormattedMessage } from 'react-intl';

import type { TradingToolTemplate } from '@/store/api/hedgerApi/hedgerApi.ts';
import type { CreateRecipientData, Recipient } from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { handleHedgerError } from '@/components/hedger/common/handleHedgerError.ts';
import { EditRecipientGrid } from '@/components/hedger/manage/recipients/components/EditRecipientGrid.tsx';
import {
  EditRecipientInputControls,
  type RecipientData,
} from '@/components/hedger/manage/recipients/components/EditRecipientInputControls.tsx';

interface CreateRecipientFrameProps {
  close: () => void;
  createRecipient: (data: CreateRecipientData) => {
    unwrap: () => Promise<Recipient>;
  };
  tradingToolTemplates: TradingToolTemplate[];
  recipientInit: CreateRecipientData;
}

export function CreateRecipientFrame(createRecipientFrameProps: CreateRecipientFrameProps) {
  const { close, createRecipient, tradingToolTemplates, recipientInit } = createRecipientFrameProps;

  const [recipientData, setRecipientData] = useState<RecipientData>(
    omit(recipientInit, ['additionalFields']),
  );

  const currentTradingToolTemplate =
    tradingToolTemplates.find(
      element => element.tradingToolTemplateId === recipientData.tradingToolTemplateId,
    ) ?? tradingToolTemplates[0];

  // Ag grid will mutate this data, but we need to have it in state in case this component re-renders
  const [additionalFields] = useState(structuredClone(recipientInit.additionalFields));

  async function onCreateClick() {
    const currentRecipient = {
      ...recipientInit,
      ...recipientData,
      tradingToolTemplateId: currentTradingToolTemplate.tradingToolTemplateId,
      additionalFields,
    };
    createRecipient(currentRecipient)
      .unwrap()
      .then(close)
      .catch(error => handleHedgerError('Error while creating recipient', error));
  }

  return (
    <div
      className="d-flex flex-column mx-auto flex-grow-1 w-75 gap-3 mb-3"
      style={{ minWidth: '60rem' }}
    >
      <div className="d-flex flex-column flex-grow-1 bg-lvl2 gap-3 p-3 border border-info">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="text-info fw-semibold">
            <FormattedMessage id="Hedger.ScenariosModal.CreateRecipient" />
          </h4>
          <div className="d-flex gap-2">
            <Button size="lg" onClick={close}>
              <FormattedMessage id="Hedger.ScenariosModal.CreateFrame.Cancel" />
            </Button>
            <Button size="lg" discreet className="btn-discreet-info " onClick={onCreateClick}>
              <FormattedMessage id="Hedger.ScenariosModal.CreateFrame.Create" />
            </Button>
          </div>
        </div>

        <EditRecipientInputControls
          recipientData={recipientData}
          onRecipientDataUpdate={setRecipientData}
          tradingToolTemplate={currentTradingToolTemplate}
          tradingToolTemplates={tradingToolTemplates}
        />
        <EditRecipientGrid
          additionalFields={additionalFields}
          tradingToolTemplate={currentTradingToolTemplate}
        />
      </div>
    </div>
  );
}
