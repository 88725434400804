import { isFetchBaseQueryError } from '@/components/common/utils/isFetchBaseQueryError.ts';
import {
  hedgerErrorSchema,
  type HedgerError,
} from '@/components/hedger/common/hedgerErrorSchema.ts';

export function getFirstHedgerErrorMessage(error: unknown): string | undefined {
  if (isFetchBaseQueryError(error)) {
    const result = hedgerErrorSchema.safeParse(error.data);
    if (result.success) {
      return result.data.errorMessages[0]?.message;
    }
  }
  return undefined;
}
export function getHedgerError(error: unknown): HedgerError | undefined {
  if (!isFetchBaseQueryError(error)) {
    return undefined;
  }
  const result = hedgerErrorSchema.safeParse(error.data);
  if (!result.success) {
    return undefined;
  }

  return result.data;
}
