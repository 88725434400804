import { useState } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import pick from 'just-pick';

import type { TradingToolTemplate } from '@/store/api/hedgerApi/hedgerApi.ts';
import type { Recipient } from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import { handleHedgerError } from '@/components/hedger/common/handleHedgerError.ts';
import { DeleteModal } from '@/components/hedger/manage/common/DeleteModal.tsx';
import {
  EditHeader,
  type TitleIds,
} from '@/components/hedger/manage/recipients/components/EditHeader.tsx';
import { EditRecipientGrid } from '@/components/hedger/manage/recipients/components/EditRecipientGrid.tsx';
import {
  EditRecipientInputControls,
  type RecipientData,
} from '@/components/hedger/manage/recipients/components/EditRecipientInputControls.tsx';

const recipientTitleIds: TitleIds = {
  Title: 'Hedger.ScenariosModal.EditRecipient.Title',
  Delete: 'Hedger.ScenariosModal.EditRecipient.Delete',
};

interface EditRecipientFrameProps {
  recipient: Recipient;
  updateRecipient: (item: Recipient) => {
    unwrap: () => Promise<Recipient>;
  };
  deleteRecipient: (item: Pick<Recipient, 'recipientId'>) => {
    unwrap: () => Promise<Recipient>;
  };
  initTradingToolTemplate: TradingToolTemplate;
  tradingToolTemplates: TradingToolTemplate[];
  closeRecipient: () => void;
}

export function EditRecipientFrame(editRecipientFrameProps: EditRecipientFrameProps) {
  const {
    recipient,
    initTradingToolTemplate,
    tradingToolTemplates,
    updateRecipient,
    deleteRecipient,
    closeRecipient,
  } = editRecipientFrameProps;
  // Ag grid will mutate this data, but we need to have it in state in case this component re-renders
  const [additionalFields] = useState(structuredClone(recipient.additionalFields));

  const [recipientData, setRecipientData] = useState<RecipientData>(
    pick(recipient, ['recipientLabel', 'tradingToolTemplateId', 'userId', 'team', 'hedgeAxis']),
  );
  const currentTradingToolTemplate =
    tradingToolTemplates.find(
      element => element.tradingToolTemplateId === recipientData.tradingToolTemplateId,
    ) ?? initTradingToolTemplate;

  const deleteModal = useModal(DeleteModal);

  const disableDelete = recipient.sharedWithScenarios.length > 0;

  async function onSaveClick() {
    const currentRecipient = {
      ...recipient,
      ...recipientData,
      additionalFields,
    };
    updateRecipient(currentRecipient)
      .unwrap()
      .then(closeRecipient)
      .catch(err => handleHedgerError('Error while saving recipient', err));
  }

  function onDeleteClick() {
    async function onConfirmClick() {
      deleteRecipient({ recipientId: recipient.recipientId })
        .unwrap()
        .then(closeRecipient)
        .catch(err => handleHedgerError('Error while deleting recipient', err));
    }

    deleteModal.show({
      onConfirmClick,
      type: 'Recipient',
    });
  }

  return (
    <div className="d-flex flex-column mx-auto w-75 gap-3 flex-grow-1 mb-3">
      <div className="d-flex flex-column flex-grow-1 bg-lvl2 gap-3 p-3 border border-info">
        <EditHeader
          titleIds={recipientTitleIds}
          onCancelClick={closeRecipient}
          onSaveClick={onSaveClick}
          onDeleteClick={onDeleteClick}
          disableDelete={disableDelete}
        />
        <EditRecipientInputControls
          id={recipient.recipientId.toString()}
          recipientData={recipientData}
          onRecipientDataUpdate={setRecipientData}
          tradingToolTemplate={currentTradingToolTemplate}
          tradingToolTemplates={tradingToolTemplates}
        />
        <EditRecipientGrid
          additionalFields={additionalFields}
          tradingToolTemplate={currentTradingToolTemplate}
        />
      </div>
    </div>
  );
}
