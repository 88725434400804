import { assertUnreachable } from '@sgme/fp';

import type { ExclusionMode } from '@/store/slices/query/querySlice.ts';

export function getExclusionClause(exclusionMode: ExclusionMode): string {
  switch (exclusionMode) {
    case 'APPLIED':
      return '[IsExcluded].[FALSE]';
    case 'ONLY_EXCLUDED_DATA':
      return '[IsExcluded].[TRUE]';
    case 'NOT_APPLIED':
      return '[IsExcluded].[AllMember]';
    default:
      assertUnreachable(exclusionMode, 'Exclusion mode not handled');
  }
}
