import type { CustomCellRendererProps } from '@ag-grid-community/react';
import { useModal } from '@ebay/nice-modal-react';
import { FormattedMessage } from 'react-intl';

import type { TemplateAdditionalField } from '@/store/api/hedgerApi/hedgerApi.ts';
import type { AdditionalFields } from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { getTitleIdFromFieldName } from '@/components/hedger/manage/recipients/components/useRecipientsColDefs.tsx';
import { ViewTableModal } from '@/components/hedger/manage/recipients/details/ViewTableModal.tsx';
import { objectKeys } from '@/utils/libs/entries.ts';

export interface ViewTableCellRendererParams
  extends CustomCellRendererProps<AdditionalFields, Record<string, string>> {
  additionalField: TemplateAdditionalField;
}

export function MapViewCellRenderer(params: ViewTableCellRendererParams) {
  const viewModal = useModal(ViewTableModal);
  const tableData = params.value!;
  const additionalField = params.additionalField;
  const actionLabel = getTitleIdFromFieldName(additionalField.name, 'View');

  const keys = objectKeys(tableData);
  const isSingle = keys.length === 1 && keys[0] === '*';

  function onViewClick(tableData: Record<string, string>) {
    viewModal.show({
      name: additionalField.name,
      data: tableData,
      titleId: actionLabel,
    });
  }

  if (isSingle) {
    return (
      <div className="d-flex flex-between align-items-center">
        <div>{tableData[keys[0]]}</div>
      </div>
    );
  }

  return (
    <div className="d-flex flex-between align-items-center">
      <Button size="sm" variant="default" onClick={() => onViewClick(tableData)}>
        <FormattedMessage id={actionLabel} />
      </Button>
    </div>
  );
}
