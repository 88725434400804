import { useCallback } from 'react';
import type {
  ColDef,
  IRichCellEditorParams,
  ISelectCellEditorParams,
  ValueFormatterParams,
  ValueParserParams,
  ValueSetterParams,
} from '@ag-grid-community/core';
import { useIntl } from 'react-intl';
import { asSequence } from 'sequency';

import { type MarketRef } from '@/store/api/hedgerApi/hedgerApi.ts';
import type { Recipient } from '@/store/api/hedgerApi/recipientsHedgerApi';
import type {
  AxisConfig,
  CurrencyType,
  HedgeAxis,
} from '@/store/api/hedgerApi/scenariosHedgerApi.ts';
import { useAppSelector } from '@/store/hooks.ts';
import { DeleteButton } from '@/components/hedger/manage/scenarios/components/DeleteButton.tsx';
import { getCurrencyLabel } from '@/utils/libs/currency.ts';

// TODO: get language??
const numberFormatter = Intl.NumberFormat('fr');

export function getMarketConfigColDef(
  editMode: 'edit' | 'view',
  hedgeAxis: HedgeAxis,
  recipients: Recipient[],
  markets: MarketRef[],
  currency: CurrencyType,
  formatHedgeAxis: (hedgeAxis: HedgeAxis) => string,
): ColDef<AxisConfig>[] {
  const recipientsById = asSequence(recipients).associateBy(r => r.recipientId);

  const { cellEditor, cellEditorParams } = getCellEditor(hedgeAxis, markets);
  const editable = editMode === 'edit';

  const colDefs: ColDef<AxisConfig>[] = [
    {
      field: 'axisId',
      headerName: formatHedgeAxis(hedgeAxis),
      cellEditor,
      cellEditorParams,
      cellEditorPopup: true,
      editable,
    },
    {
      field: 'active',
      headerName: 'Active',
      editable,
      // autoHeight: true,
      // cellRenderer: ActiveSwitcher,
    },
    {
      field: 'hedgePercent',
      headerName: '% to Hedge',
      valueFormatter: ({ value }: Partial<ValueFormatterParams>) => value * 100 + '%',
      editable,
    },
    {
      field: 'greek',
      headerName: 'Greek',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['REAL_TIME', 'FIXING', 'INIT'],
      },
      editable,
    },
    {
      field: 'advParticipationPercent',
      headerName: 'ADV limit',
      valueFormatter: ({ value }: Partial<ValueFormatterParams>) => value * 100 + '%',
      editable,
    },
    {
      field: 'limitMin',
      headerName: `Min order (k${getCurrencyLabel(currency)})`,
      valueFormatter: ({ value }) => numberFormatter.format(value / 1000),
      valueGetter: ({ data }) => (data === undefined ? undefined : data.limitMin / 1000),
      valueSetter: ({ newValue, data }) => {
        data.limitMin = newValue * 1000;
        return true;
      },
      initialWidth: 150,
      editable,
    },
    {
      field: 'limitMax',
      headerName: `Max order (k${getCurrencyLabel(currency)})`,
      valueFormatter: ({ value }) => numberFormatter.format(value / 1000),
      valueGetter: ({ data }) => (data === undefined ? undefined : data.limitMax / 1000),
      valueSetter: ({ newValue, data }) => {
        data.limitMax = newValue * 1000;
        return true;
      },
      initialWidth: 150,
      editable,
    },
    {
      field: 'recipientId',
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: recipients.map(recipient => recipient.recipientId),
        searchType: 'match',
        allowTyping: true,
        filterList: true,
        highlightMatch: true,
      },
      cellEditorPopup: true,

      valueParser: (params: ValueParserParams<AxisConfig>) => {
        const recipient = recipients.find(
          recipient => recipient.recipientLabel === params.newValue,
        );
        if (recipient == null) {
          return undefined;
        }
        return recipient.recipientId;
      },

      valueSetter: (params: ValueSetterParams<AxisConfig>) => {
        const recipientId = Number.parseInt(params.newValue);
        if (isNaN(recipientId)) {
          return false;
        }
        params.data.recipientId = recipientId;
        return true;
      },
      valueFormatter: (params: ValueFormatterParams<AxisConfig, number>) => {
        const recipientId = params.value;
        if (recipientId == null) {
          return '';
        }
        return recipientsById.get(recipientId)?.recipientLabel ?? '';
      },
      editable,
      headerName: 'Recipient',
      minWidth: 180,
    },
  ];

  if (editable) {
    colDefs.push({
      colId: 'delete',
      headerName: 'Delete',
      cellRenderer: DeleteButton,
      editable: false,
      width: 100,
    });
  }

  return colDefs;
}

type CellEditor =
  | {
      cellEditor: 'agRichSelectCellEditor';
      cellEditorParams: IRichCellEditorParams;
    }
  | {
      cellEditor: 'agSelectCellEditor';
      cellEditorParams: ISelectCellEditorParams;
    }
  | {
      cellEditor: undefined;
      cellEditorParams: undefined;
    };

export function axisConfigToMap(axisConfig: AxisConfig[]): Record<string, AxisConfig> {
  return axisConfig.reduce(
    (acc, axisConfig) => {
      acc[axisConfig.axisId] = axisConfig;
      return acc;
    },
    {} as Record<string, AxisConfig>,
  );
}

function getCellEditor(hedgeAxis: HedgeAxis, markets: MarketRef[]): CellEditor {
  switch (hedgeAxis) {
    case 'MarketCode':
      return {
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: markets.map(marketRef => marketRef.marketId),
          searchType: 'match',
          allowTyping: true,
          filterList: true,
          highlightMatch: true,
        },
      };
    default:
      return { cellEditor: undefined, cellEditorParams: undefined };
  }
}

export function useFormatHedgeAxis(): (hedgeAxis: HedgeAxis) => string {
  const customLabels: Record<string, string> = useAppSelector(
    state => state.userPreferences.customHierarchiesLabels,
  );
  const { formatMessage } = useIntl();
  return useCallback(
    hedgeAxis => {
      const customLabel = customLabels[hedgeAxis];
      return customLabel !== undefined && customLabel !== ''
        ? customLabel
        : formatMessage({ id: `Hedger.HedgeAxis.${hedgeAxis}` });
    },
    [customLabels, formatMessage],
  );
}
