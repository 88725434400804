import type {
  CellEditRequestEvent,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  RowClassParams,
  RowClassRules,
} from '@ag-grid-community/core';

import {
  getOrderIdKey,
  useGetOrderGroupQuery,
  useUpdateOrderMutation,
  type OrderGroup,
} from '@/store/api/hedgerApi/hedgerApi.ts';
import type { Order } from '@/store/api/hedgerApi/hedgerTypes.ts';
import { useGetScenariosQuery, type Scenario } from '@/store/api/hedgerApi/scenariosHedgerApi.ts';
import { useAppDispatch, useAppSelector, useAppStore } from '@/store/hooks.ts';
import { applyGridState, captureFromGridState } from '@/web/presets/gridState.ts';
import { Loader } from '@/components/common/bootstrap/Loader.tsx';
import { getOrderColDefs } from '@/components/hedger/order/getOrderColDefs.tsx';
import { getHedgerGridOptions } from '@/components/hedger/order/gridOptions.ts';
import { HedgerOrderToolsPanel } from '@/components/hedger/order/topPanel/HedgerOrderToolsPanel.tsx';
import { KpiPanel } from '@/components/hedger/order/topPanel/KpiPanel.tsx';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';

import { useMemo, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';

import '../../ag-grid-theme-era.css';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import debounce from 'debounce';
import groupBy from 'just-group-by';

import {
  useGetRecipientsQuery,
  type Recipient,
} from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import { saveHedgerGridStateToSessionServiceThunk } from '@/store/slices/prefs/hedgerSlice.ts';
import { FetchErrorView } from '@/components/common/utils/FetchErrorView.tsx';
import { handleHedgerError } from '@/components/hedger/common/handleHedgerError.ts';
import { objectKeys } from '@/utils/libs/entries.ts';
import { isQueryHookResultReady } from '@/utils/rtk/predicates.ts';

interface HedgerOrderPageProps {
  orderGroupId: number;
}

type HedgerOrderProps = {
  scenarios: Scenario[];
  recipients: Recipient[];
  orderGroup: OrderGroup;
};

const rowClassRules: RowClassRules<Order> = {
  'text-secondary': (params: RowClassParams<Order>) => params.data?.active === false,
};

const gridOptions = getHedgerGridOptions();
export function HedgerOrderPage({ orderGroupId }: HedgerOrderPageProps): JSX.Element {
  const getRecipientsResult = useGetRecipientsQuery();
  const getOrderGroupResult = useGetOrderGroupQuery({ orderGroupId });
  const getScenarioQueryResult = useGetScenariosQuery();

  if (
    !isQueryHookResultReady(getScenarioQueryResult) ||
    !isQueryHookResultReady(getRecipientsResult) ||
    !isQueryHookResultReady(getOrderGroupResult)
  ) {
    return <Loader />;
  }

  if (getScenarioQueryResult.isError) {
    return <FetchErrorView error={getScenarioQueryResult.error} />;
  }
  if (getRecipientsResult.isError) {
    return <FetchErrorView error={getRecipientsResult.error} />;
  }
  if (getOrderGroupResult.isError) {
    return <FetchErrorView error={getOrderGroupResult.error} />;
  }

  const orderGroup = getOrderGroupResult.data;
  const scenarios = getScenarioQueryResult.data.scenarios;
  const recipients = getRecipientsResult.data.recipients;

  return <HedgerOrder orderGroup={orderGroup} scenarios={scenarios} recipients={recipients} />;
}

function HedgerOrder({ scenarios, recipients, orderGroup }: HedgerOrderProps) {
  const store = useAppStore();
  const dispatch = useAppDispatch();
  const { numberFormat, negativeNumberFormat } = useAppSelector(state => state.userPreferences);
  const [updateOrderTrigger] = useUpdateOrderMutation();
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);

  const orderColumnDefs = useMemo(() => {
    return getOrderColDefs(recipients, numberFormat, negativeNumberFormat);
  }, [recipients, numberFormat, negativeNumberFormat]);

  const onGridReady = ({ api }: GridReadyEvent) => {
    setGridApi(api);
    const savedGridState = store.getState().hedger.gridState;
    if (savedGridState === undefined) {
      return;
    }
    applyGridState(api, savedGridState);
    api.setFilterModel(savedGridState.filterModel ?? null);
  };
  const onGridStateChanged = debounce(() => {
    if (gridApi === undefined) {
      return;
    }
    dispatch(saveHedgerGridStateToSessionServiceThunk(captureFromGridState(gridApi)));
  }, 1000);

  const onCellEditRequest = async ({ colDef, node, newValue }: CellEditRequestEvent) => {
    const field = colDef.field;
    if (field === undefined) {
      return;
    }
    updateOrderTrigger({
      orderGroupId: orderGroup.orderGroupId,
      updates: [{ orderId: node.data.orderId, [field]: newValue }],
    })
      .unwrap()
      .catch(err => handleHedgerError(`Error while updating order`, err));
  };

  const scenarioName = scenarios.find(s => s.scenarioId === orderGroup.scenarioId)?.name;

  return (
    <div className="d-flex flex-column h-100 pt-2">
      <div className="d-flex justify-content-between align-items-center px-4">
        <div className="d-flex">
          <div className="display-3 me-4 pe-2">{scenarioName}</div>
          <KpiPanel ordersMetrics={orderGroup.ordersMetrics} numberFormat={numberFormat} />
        </div>
        <HedgerOrderToolsPanel orderGroup={orderGroup} />
      </div>
      <div className="d-flex flex-column h-100 px-4">
        <InactiveOrdersBanner orderGroup={orderGroup} />
        <AgGridReact<Order>
          modules={[
            ClientSideRowModelModule,
            SideBarModule,
            ColumnsToolPanelModule,
            FiltersToolPanelModule,
          ]}
          stopEditingWhenCellsLoseFocus
          className="ag-theme-alpine ag-theme-era"
          columnDefs={orderColumnDefs}
          rowData={orderGroup.orders}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          onColumnPinned={onGridStateChanged}
          onColumnVisible={onGridStateChanged}
          onColumnMoved={onGridStateChanged}
          onSortChanged={onGridStateChanged}
          onColumnResized={onGridStateChanged}
          onFilterChanged={onGridStateChanged}
          rowClassRules={rowClassRules}
          getRowId={(params: GetRowIdParams<Order>) => getOrderIdKey(params.data.orderId)}
          readOnlyEdit
          onCellEditRequest={onCellEditRequest}
        />
      </div>
    </div>
  );
}

export function InactiveOrdersBanner({
  orderGroup,
}: {
  orderGroup: OrderGroup;
}): JSX.Element | null {
  const ordersWithInactiveReasons = orderGroup.orders.filter(order => !order.active);
  const inactiveOrdersData = groupBy(
    ordersWithInactiveReasons.flatMap(order => order.inactiveReasons),
    reason => reason.type,
  );

  const message = objectKeys(inactiveOrdersData)
    .map(key => `${inactiveOrdersData[key].length} ${key}`)
    .join(' / ');

  if (ordersWithInactiveReasons.length === 0) {
    return null;
  }
  return (
    <div className="alert alert-discreet-warning py-8px mt-2 mb-2" role="banner">
      <i className="icon icon-sm lh-1 me-2">error_outline</i>
      <span className="fw-bold me-1">{ordersWithInactiveReasons.length} inactive orders :</span>
      {message}
    </div>
  );
}
