import type { CellClassRules, ColDef, ValueGetterParams } from '@ag-grid-community/core';

import { getCellValueFormatter } from '@/core/format.ts';
import type { MeasureType } from '@/core/measures.ts';
import type { Order } from '@/store/api/hedgerApi/hedgerTypes.ts';
import type { Recipient } from '@/store/api/hedgerApi/recipientsHedgerApi.ts';
import type {
  NegativeNumberFormat,
  NumberFormat,
} from '@/store/slices/prefs/userPreferencesSlice.ts';
import { absValueComparator } from '@/components/equityRisk/gridMappings/comparators.ts';
import { ActiveSwitcher } from '@/components/hedger/order/ActiveSwitcher.tsx';
import { disableAllActiveOrders } from '@/components/hedger/order/disableAllActiveOrders.tsx';
import { InactiveReasonsTooltip } from '@/components/hedger/order/InactiveReasonsTooltip.tsx';

export interface OrderColumn extends ColDef<Order> {
  type?: 'rightAligned' | 'leftAligned';
  format?: MeasureType;
}

const orderColumns: OrderColumn[] = [
  {
    field: 'orderId.hedgeAxisId',
    headerName: 'Hegde Axis',
  },
  {
    field: 'underlying',
    format: 'text',
    headerName: 'Underlying Bloomberg',
  },
  {
    field: 'eliotId',
    format: 'text',
    headerName: 'Underlying Eliot',
  },
  {
    field: 'rtGenOrderPassing',
    format: 'text',
    headerName: 'Underlying RTGen',
  },
  {
    field: 'way',
    format: 'text',
  },
  {
    field: 'quantity',
    headerName: 'Qty',
    format: 'quantity',
    initialWidth: 100,
    type: 'rightAligned',
    editable: true,
  },
  {
    field: 'deltaCurrency',
    headerName: 'Delta',
    format: 'currency',
    initialWidth: 100,
    type: 'rightAligned',
    comparator: absValueComparator,
  },
  {
    field: 'hedgePercent',
    headerName: 'Hedge %',
    format: 'percentage',
    type: 'rightAligned',
    editable: true,
  },
  {
    field: 'refSpot',
    headerName: 'Spot',
    format: 'price',
    type: 'rightAligned',
  },

  {
    field: 'portfolio',
    format: 'text',
    type: 'rightAligned',
  },
  {
    field: 'gop',
    format: 'text',
  },
  {
    field: 'market',
    format: 'text',
  },
];

export function getOrderColDefs(
  recipients: Recipient[],
  numberFormat: NumberFormat,
  negativeNumberFormat: NegativeNumberFormat,
): ColDef<Order>[] {
  const orderColDefs: ColDef<Order>[] = orderColumns.map(
    ({ format, ...orderColumn }: OrderColumn): ColDef<Order> => {
      return {
        ...orderColumn,
        cellClassRules: getHedgerCellClassRules(orderColumn),
        valueFormatter:
          format !== undefined
            ? getCellValueFormatter(numberFormat, negativeNumberFormat, format)
            : undefined,
      };
    },
  );

  return orderColDefs.concat(getCustomOrderColDefs(recipients));
}

function getCustomOrderColDefs(recipients: Recipient[]): OrderColumn[] {
  return [
    {
      sortIndex: 1,
      field: 'active',
      headerName: 'Active',
      minWidth: 100,
      autoHeight: true,
      cellRenderer: ActiveSwitcher,
      tooltipComponent: InactiveReasonsTooltip,
      tooltipField: 'active',
      mainMenuItems: params => [
        ...params.defaultItems,
        'separator',
        {
          name: 'Disable All',
          tooltip: 'Disable all the switchers on the column',
          action: disableAllActiveOrders,
        },
      ],
    },

    {
      sortIndex: 0,
      field: 'recipientId',
      headerName: 'Recipient',
      valueGetter: (params: ValueGetterParams<Order>) =>
        recipients.find(recipient => recipient.recipientId === params.data?.recipientId)
          ?.recipientLabel ?? '',
    },
  ];
}

function getHedgerCellClassRules(_: OrderColumn): CellClassRules<Order> {
  return {
    'text-danger': ({ value, data }) => value < 0 && data?.active === true,
  };
}
