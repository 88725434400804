import { useState } from 'react';
import { EditTable } from './EditTable.tsx';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { CancelConfirmFooter } from '@/components/common/bootstrap/CancelConfirmFooter.tsx';
import type { IntlMessages } from '@/types/intl';

export interface EditTableModalProps {
  titleId?: IntlMessages;
  data: Record<string, string>;
  headers: [string, string];
}

export interface EditTableModalResult extends Record<string, string> {}

export const EditTableModal = NiceModal.create(function EditTableModal(props: EditTableModalProps) {
  const { data, titleId, headers } = props;

  const modal = useModal();

  const [tableData, setTableData] = useState<Record<string, string>>(data);

  function onTableUpdate(newData: Record<string, string>) {
    setTableData(newData);
  }

  function handleConfirm() {
    modal.resolve(tableData);
  }

  return (
    <BootstrapModal
      style={{ minHeight: '28em', minWidth: '51em' }}
      footer={<CancelConfirmFooter onConfirm={handleConfirm} />}
      titleId={titleId}
    >
      <EditTable data={tableData} onTableUpdate={onTableUpdate} headers={headers} />
    </BootstrapModal>
  );
});
