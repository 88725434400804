import type { CustomCellRendererProps } from '@ag-grid-community/react';

import { Button } from '@/components/common/bootstrap/Button.tsx';

export interface DeleteCellRendererParams extends CustomCellRendererProps {}

export function DeleteCellRenderer({ api, data }: DeleteCellRendererParams) {
  function onDeleteClick() {
    const rowData = api.getGridOption('rowData')!;
    const dataIndex = rowData?.findIndex(rowData => rowData === data);
    rowData.splice(dataIndex, 1);
    api.setGridOption('rowData', rowData);
  }

  return (
    <Button className="btn-icon-text" flat variant="danger" onClick={onDeleteClick}>
      <i className="icon icon-sm icon-">delete_outline</i>
    </Button>
  );
}
