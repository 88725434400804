import { hedgerApi } from '@/store/api/hedgerApi/hedgerApi.ts';

type Scenarios = { scenarios: Scenario[] };

export interface AxisConfig {
  axisId: string;
  active: boolean;
  recipientId: number;
  hedgePercent: number;
  greek: string;
  advParticipationPercent: number;
  limitMin: number;
  limitMax: number;
  delete?: string;
}

export const HedgeAxes = [
  'MarketCode',
  'UnderlyerType',
  'UnderlyerCode',
  'UnderlyerMarketPlace',
  'DeskCustomAxis1',
  'DeskCustomAxis2',
  'DeskCustomAxis3',
  'DeskCustomAxis4',
  'DeskCustomAxis5',
  // Special axis
  'MarketCodeGop',
] as const;
export type HedgeAxis = (typeof HedgeAxes)[number];

export const DeactivatedValues = ['UNKNOWN', 'BASKET', 'INDEX', 'SHARE', 'FUND'] as const;
export type DeactivatedValuesType = (typeof DeactivatedValues)[number];
export const Currencies = ['USD', 'EUR'] as const;
export type CurrencyType = (typeof Currencies)[number];
export interface Scenario {
  scenarioId: number;
  userId: string;
  team: string;
  name: string;
  outputOptions?: string[];
  ordersMetricsCurrency: CurrencyType;
  deactivatedProductTypes: DeactivatedValuesType[];
  deactivatedTickers: string[];
  groupedGopsRules: string[][];
  axisConfigs: Record<string, AxisConfig>;
  hedgeAxis: HedgeAxis;
}
export const scenariosHedgerApi = hedgerApi.injectEndpoints({
  endpoints: builder => ({
    getScenarios: builder.query<Scenarios, void>({
      query: () => `scenarios/current-user`,
      providesTags: result =>
        result?.scenarios
          ? [
              ...result?.scenarios.map(scenario => ({
                type: 'Scenario' as const,
                id: scenario.scenarioId,
              })),
              { type: 'Scenario' },
            ]
          : [{ type: 'Scenario' }],
    }),
    createScenario: builder.mutation<Scenario, Omit<Scenario, 'scenarioId'>>({
      query: arg => ({
        url: `scenarios`,
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: () => [{ type: 'Scenario' }],
    }),
    updateScenario: builder.mutation<Scenario, Scenario>({
      query: arg => ({
        url: `scenarios`,
        method: 'PUT',
        body: arg,
      }),
      invalidatesTags: (result, error, arg, meta) => [{ type: 'Scenario', id: arg.scenarioId }],
    }),
    deleteScenario: builder.mutation<Scenario, Pick<Scenario, 'scenarioId'>>({
      query: arg => ({
        url: `scenarios/${arg.scenarioId}`,
        method: 'DELETE',
        body: arg,
      }),
      invalidatesTags: (result, error, arg, meta) => [{ type: 'Scenario', id: arg.scenarioId }],
    }),

    resetScenarios: builder.mutation<void, void>({
      query: () => ({
        url: `scenarios/reset`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'Scenario' }],
    }),
  }),
});

export const {
  useGetScenariosQuery,

  useUpdateScenarioMutation,
  useDeleteScenarioMutation,
  useCreateScenarioMutation,
  useResetScenariosMutation,
} = scenariosHedgerApi;
