interface BlotterCriteria {
  AssetClass: {
    Operator: string;
    Values: string[];
  };
  InstrumentName: {
    Operator: string;
    Values: string[];
  };
  TradeDate: {
    Operator: string;
    DateOne: string;
    DateTwo: string;
  };
}

export function getBlotterCriteria(): BlotterCriteria {
  const now = new Date().toISOString().split('T')[0];

  return {
    AssetClass: {
      Operator: 'EQUAL',
      Values: ['EQUITYLISTED'],
    },
    InstrumentName: {
      Operator: 'EQUAL',
      Values: ['EquitySecurity'],
    },
    TradeDate: {
      Operator: 'BETWEEN',
      DateOne: now,
      DateTwo: now,
    },
  };
}
