import { asSequence } from 'sequency';

import { allHierarchies, type Hierarchy } from '@/core/hierachies.ts';
import { getMeasureById, type MeasureId } from '@/core/measures.ts';
import {
  getFromClause,
  getMdxQueryTemplateForWidget,
  getWidgetColumnClause,
  getWidgetRowClause,
  getWidgetSlicerClause,
} from '@/core/query/mdx/widgets.ts';
import { type WidgetConfigParams } from '@/store/slices/widget/widgetsSlice.ts';
import {
  widgetDescription,
  type WidgetCategory,
  type WidgetDef,
  type WidgetType,
} from '@/store/slices/widget/widgetTypes.ts';
import { getMeasureName } from '@/components/equityRisk/gridMappings/ColumnMapping.ts';
import { type CubeMode } from '@/types/AppConfig.ts';

export function getMeasureWidgetName(measureId: MeasureId, cubeMode: CubeMode): string {
  const measure = getMeasureById(measureId);
  return getMeasureName(measure, cubeMode);
}

export type ViewWidgetParams = {
  spotVarThreshold?: {
    type: 'percentage';
    label: string;
    value: number;
  };
  numberOfLines?: {
    type: 'number';
    label: string;
    maxValue: number;
    value: number;
  };
  rowAggregation?: {
    type: 'select';
    label: string;
    value: Hierarchy;
    allowedValues: Hierarchy[];
  };
  columnAggregation?: {
    type: 'select';
    label: string;
    value: Hierarchy;
    allowedValues: Hierarchy[];
  };
  metric?: {
    type: 'select';
    label: string;
    value: MeasureId;
    allowedValues: MeasureId[];
  };
};

const size = { height: 300, width: 600 };
export type WidgetParams = ViewWidgetParams & WidgetConfigParams;

export const AllWidgets = [
  {
    type: 'topDelta',
    category: 'Delta',
    name: 'Top Delta',
    title: 'Top Delta',

    columns: [
      { type: 'hierarchy', name: 'UnderlyerCode' },
      {
        type: 'measure',
        measureId: 'DeltaCurrencyIntraday',
      },
      {
        type: 'measure',
        measureId: 'UnderlyerSpotRTMove',
      },
    ],
    description: 'Highest delta',

    getQuery: (
      { cubeName, perimeters, cubeMode, valueDate, exclusionMode },
      widgetParams: WidgetConfigParams,
    ) => {
      const selectedHierarchy = widgetParams.rowAggregation?.value;
      const numberOfLines = widgetParams.numberOfLines?.value;

      const column = getWidgetColumnClause(
        ['DeltaCurrencyIntraday', 'UnderlyerSpotRTMove'],
        cubeMode,
      );
      const row = getWidgetRowClause(
        'DeltaCurrencyIntraday',
        selectedHierarchy,
        numberOfLines,
        cubeMode,
      );
      const from = getFromClause(cubeName, perimeters);
      const slicer = getWidgetSlicerClause(valueDate, exclusionMode);

      return getMdxQueryTemplateForWidget({ slicer, from, row, column });
    },

    params: {
      numberOfLines: { label: 'Number of lines', value: 5, maxValue: 100, type: 'number' },
      rowAggregation: {
        label: 'Aggregation',
        value: 'UnderlyerCode',
        type: 'select',
        allowedValues: [...allHierarchies],
      },
    },
    size,
  },
  {
    type: 'topDeltaAbsolute',
    category: 'Delta',
    name: 'Top Delta Absolute',
    title: 'Top Delta Absolute',

    columns: [
      { type: 'hierarchy', name: 'UnderlyerCode' },
      {
        type: 'measure',
        measureId: 'DeltaCurrencyIntraday',
      },
      {
        type: 'measure',
        measureId: 'UnderlyerSpotRTMove',
      },
    ],
    description: 'Highest delta',

    getQuery: (
      { cubeName, perimeters, cubeMode, valueDate, exclusionMode },
      widgetParams: WidgetConfigParams,
    ) => {
      const selectedHierarchy = widgetParams.rowAggregation?.value;
      const numberOfLines = widgetParams.numberOfLines?.value;

      const column = getWidgetColumnClause(
        ['DeltaCurrencyIntraday', 'UnderlyerSpotRTMove'],
        cubeMode,
      );
      const row = getWidgetRowClause(
        'DeltaCurrencyIntraday',
        selectedHierarchy,
        numberOfLines,
        cubeMode,
        true,
      );
      const from = getFromClause(cubeName, perimeters);
      const slicer = getWidgetSlicerClause(valueDate, exclusionMode);

      return getMdxQueryTemplateForWidget({ slicer, from, row, column });
    },

    params: {
      numberOfLines: { label: 'Number of lines', value: 5, maxValue: 100, type: 'number' },
      rowAggregation: {
        label: 'Aggregation',
        value: 'UnderlyerCode',
        type: 'select',
        allowedValues: [...allHierarchies],
      },
    },
    size,
  },
  {
    type: 'TopGamma',
    name: 'Top Gamma',
    title: 'Top Gamma Move',
    category: 'Gamma',
    columns: [
      { type: 'hierarchy', name: 'UnderlyerCode' },
      { type: 'measure', measureId: 'Gamma' },
      {
        type: 'measure',
        measureId: 'UnderlyerSpotRTMove',
      },
    ],
    description: widgetDescription,

    getQuery: ({ perimeters, cubeName, cubeMode, valueDate, exclusionMode }, widgetParams) => {
      const underlyerSpot = getMeasureWidgetName('UnderlyerSpotRTMove', cubeMode);
      const gamma = getMeasureWidgetName('Gamma', cubeMode);

      const slicer = getWidgetSlicerClause(valueDate, exclusionMode);
      const row = `TopCount(FILTER( [UnderlyerCode].[UnderlyerCode].[UnderlyerCode], ABS([Measures].[${underlyerSpot}]) > ${widgetParams.spotVarThreshold?.value}), 5, [Measures].[${gamma}])`;
      const from = getFromClause(cubeName, perimeters);
      const column = `[Measures].[${gamma}], [Measures].[${underlyerSpot}]`;

      return getMdxQueryTemplateForWidget({ row, column, from, slicer });
    },
    params: { spotVarThreshold: { value: 0.01, type: 'percentage', label: 'Spotvar Threshold' } },
    size,
  },
  {
    type: 'bottomDelta',
    name: 'Bottom Delta',
    category: 'Delta',
    title: 'Bottom Delta',
    columns: [
      { type: 'hierarchy', name: 'UnderlyerCode' },
      {
        type: 'measure',
        measureId: 'DeltaCurrencyIntraday',
      },
      {
        type: 'measure',
        measureId: 'UnderlyerSpotRTMove',
      },
    ],
    description: 'Lowest delta',

    getQuery: (
      { cubeName, perimeters, cubeMode, valueDate, exclusionMode },
      widgetParams: WidgetConfigParams,
    ) => {
      const selectedHierarchy = widgetParams.rowAggregation?.value;
      const numberOfLines = widgetParams.numberOfLines?.value;

      const underlyerSpot = getMeasureWidgetName('UnderlyerSpotRTMove', cubeMode);
      const delta = getMeasureWidgetName('DeltaCurrencyIntraday', cubeMode);
      const formattedHierarchy = `[${selectedHierarchy}].[${selectedHierarchy}].[${selectedHierarchy}]`;

      const row = `BottomCount
            (${formattedHierarchy}, ${numberOfLines}, [Measures].[${delta}])`;
      const column = `[Measures].[${delta}], [Measures].[${underlyerSpot}]`;
      const from = getFromClause(cubeName, perimeters);
      const slicer = getWidgetSlicerClause(valueDate, exclusionMode);

      return getMdxQueryTemplateForWidget({ column, row, from, slicer });
    },
    params: {
      numberOfLines: { label: 'Number of lines', value: 5, maxValue: 100, type: 'number' },
      rowAggregation: {
        label: 'Aggregation',
        value: 'UnderlyerCode',
        type: 'select',
        allowedValues: [...allHierarchies],
      },
    },
    size,
  },
  {
    type: 'multiAxis',
    name: 'Multi Axis',
    category: 'MultiAxis',
    title: 'Multi Axis',

    description: 'POC of multi axis widget',
    getQuery: (widgetQueryParams, widgetParams: WidgetConfigParams) => {
      const { cubeName, perimeters, cubeMode, valueDate, exclusionMode } = widgetQueryParams;

      const rowAggregation = widgetParams.rowAggregation?.value;
      const hierarchy = widgetParams.columnAggregation?.value;
      const measureId = widgetParams.metric?.value;

      const measureName = measureId !== undefined ? getMeasureWidgetName(measureId, cubeMode) : '';
      const column = `TopCount([${hierarchy}].[${hierarchy}].[${hierarchy}],10,[Measures].[Measures].[${measureName}]) * [Measures].[Measures].[${measureName}]`;

      const row = `NON EMPTY TopCount([${rowAggregation}].[${rowAggregation}].[${rowAggregation}],10,[Measures].[Measures].[${measureName}])`;

      const from = getFromClause(cubeName, perimeters);
      const slicer = getWidgetSlicerClause(valueDate, exclusionMode);

      return `
          SELECT NON EMPTY {${column} }
          ON COLUMNS,
              ${row}
              ON ROWS
          FROM ( ${from})
              ${slicer}
      `;
    },

    params: {
      rowAggregation: {
        label: 'Row axis',
        value: 'ProfitCenter',
        type: 'select',
        allowedValues: ['ProductType', 'ProductMaturity', 'ProfitCenter', 'GOP'],
      },
      columnAggregation: {
        label: 'Column axis',
        value: 'ProductType',
        type: 'select',
        allowedValues: ['ProductType', 'ProductMaturity', 'ProfitCenter', 'GOP'],
      },
      metric: {
        label: 'Metric',
        value: 'DeltaCurrencyIntraday',
        type: 'select',
        allowedValues: ['DeltaCurrencyIntraday'],
      },
    },
    size,
  },
  {
    type: 'topVegaDay',
    name: 'Top Vega Day',
    category: 'Vega',
    title: 'Top Vega Day',
    columns: [
      { type: 'hierarchy', name: 'UnderlyerCode' },
      {
        type: 'measure',
        measureId: 'GammaDaily',
      },
      {
        type: 'measure',
        measureId: 'VegaDaily',
      },
      {
        type: 'measure',
        measureId: 'ThetaDaily',
      },
    ],
    description: 'Underlyers with highest Vega for the day',

    getQuery: ({ cubeName, cubeMode, valueDate, perimeters, exclusionMode }) => {
      const gammaDay = getMeasureWidgetName('GammaDaily', cubeMode);
      const vegaDay = getMeasureWidgetName('VegaDaily', cubeMode);
      const thetaDay = getMeasureWidgetName('ThetaDaily', cubeMode);

      const column = `[Measures].[${gammaDay}], [Measures].[${vegaDay}], [Measures].[${thetaDay}]`;
      const row = `TopCount( [UnderlyerCode].[UnderlyerCode].[UnderlyerCode], 5, [Measures].[${vegaDay}])`;
      const from = getFromClause(cubeName, perimeters);
      const slicer = getWidgetSlicerClause(valueDate, exclusionMode);

      return getMdxQueryTemplateForWidget({ column, row, from, slicer });
    },
    params: {},
    size,
  },
  {
    type: 'topGammaDay',
    name: 'Top Gamma Day',
    category: 'Gamma',
    title: 'Top Gamma Day',
    columns: [
      { type: 'hierarchy', name: 'UnderlyerCode' },
      {
        type: 'measure',
        measureId: 'GammaDaily',
      },
      {
        type: 'measure',
        measureId: 'VegaDaily',
      },
      {
        type: 'measure',
        measureId: 'ThetaDaily',
      },
    ],
    description: 'Underlyers with highest Gamma for the day',

    getQuery: ({ cubeName, cubeMode, valueDate, perimeters, exclusionMode }) => {
      const gammaDay = getMeasureWidgetName('GammaDaily', cubeMode);
      const vegaDay = getMeasureWidgetName('VegaDaily', cubeMode);
      const thetaDay = getMeasureWidgetName('ThetaDaily', cubeMode);

      const column = `[Measures].[${gammaDay}], [Measures].[${vegaDay}], [Measures].[${thetaDay}]`;
      const row = `TopCount( [UnderlyerCode].[UnderlyerCode].[UnderlyerCode], 5, [Measures].[${gammaDay}])`;
      const from = getFromClause(cubeName, perimeters);
      const slicer = getWidgetSlicerClause(valueDate, exclusionMode);

      return getMdxQueryTemplateForWidget({ column, row, from, slicer });
    },
    params: {},
    size,
  },
] as const satisfies Omit<WidgetDef<WidgetParams>, 'id'>[];

export const WidgetsByType = asSequence(AllWidgets).associateBy<WidgetType>(widget => widget.type);
export const WidgetsByCategory = asSequence(AllWidgets).groupBy<
  Omit<WidgetDef<WidgetParams>, 'id'>,
  WidgetCategory
>(widget => widget.category);

WidgetsByCategory.set('General', AllWidgets);
