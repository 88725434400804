import { SgwtHelpCenter } from '@sgwt/sgwt-widgets-react';

import { getConfig } from '@/utils/config/config.ts';
import { getUserInfo } from '@/utils/sgwt/userPermission.ts';

export function HelpCenter(): JSX.Element {
  return (
    <aside>
      <SgwtHelpCenter
        applicationId={import.meta.env.VITE_ACCOUNT_CENTER_APP_ID}
        mailSubject={`[ERA: ${getConfig().environment}] - Help request from: ${getUserInfo()?.mail}`}
        allowScreenshot
      />
    </aside>
  );
}
