import { Button } from '@/components/common/bootstrap/Button.tsx';
import { objectEntries } from '@/utils/libs/entries.ts';

export type Entry = {
  key: string;
  value: string;
};

interface TableProps {
  headers?: [string, string];
  data: Record<string, string>;
  onTableUpdate: (newData: Record<string, string>) => void;
}

export function EditTable({ data, onTableUpdate, headers = ['Label', 'Value'] }: TableProps) {
  const entries: Entry[] = objectEntries(data).map(([key, value]) => ({ key, value }));

  function updateData() {
    onTableUpdate(Object.fromEntries(entries.map(e => [e.key, e.value])));
  }

  function onAddValue() {
    entries.push({ key: '', value: '' });
    updateData();
  }

  return (
    <div className="d-flex flex-column gap-4">
      <table className="table table-sm table-bordered">
        <thead>
          <tr>
            <th>{headers[0]}</th>
            <th>{headers[1]}</th>
            <th className="text-center p-0">
              <i className="icon icon-sm">delete_outline</i>
            </th>
          </tr>
        </thead>
        <tbody>
          {entries.map((entry, index) => {
            function onChange(entryKey: keyof Entry, newValue: string) {
              entries[index][entryKey] = newValue;
              updateData();
            }
            function onDelete() {
              delete entries[index];
              updateData();
            }

            return (
              <EditRowData key={index} onChange={onChange} entry={entry} onDelete={onDelete} />
            );
          })}
        </tbody>
      </table>
      <Button className="d-flex gap-3" onClick={onAddValue}>
        <i className="icon icon-sm">add</i>
        Add value
      </Button>
    </div>
  );
}

type EditRowDataProps = {
  entry: Entry;
  onDelete: () => void;
  onChange: (entryKey: keyof Entry, newValue: string) => void;
};

function EditRowData(editRowDataProps: EditRowDataProps): JSX.Element | null {
  const { entry, onChange, onDelete } = editRowDataProps;
  const { key, value } = entry;
  return (
    <tr>
      <td className="d-flex p-0 justify-content-center align-items-center align-items-stretch">
        <input
          className="d-flex form-control w-100 h-100 align-self-stretch"
          onChange={event => onChange('key', event.target.value)}
          type="text"
          value={key}
        />
      </td>
      <td className="p-0">
        <input
          className="d-flex form-control"
          onChange={event => onChange('value', event.target.value)}
          type="text"
          value={value}
        />
      </td>
      <td width="45px" className="p-0">
        <Button
          className="d-flex btn btn-md btn-flat-secondary"
          flat
          variant="danger"
          onClick={onDelete}
        >
          <i className="icon icon-sm">delete_outline</i>
        </Button>
      </td>
    </tr>
  );
}
