import { useCallback, useMemo, useRef, useState } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type { ColDef, GridApi, PasteEndEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import NiceModal from '@ebay/nice-modal-react';

import {
  rulesModels,
  type RuleCategory,
  type RulesModel,
} from '@/store/api/rulesApi/rulesModels.ts';

import '@/store/api/rulesApi/rulesApi.ts';

import { useAppSelector } from '@/store/hooks.ts';
import { queryCacheSlice } from '@/store/slices/queryCache/queryCacheSlice.ts';
import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { CancelConfirmFooter } from '@/components/common/bootstrap/CancelConfirmFooter.tsx';
import { getModalColDefs } from '@/components/rules/addRulesBatchModal/getModalColDefs.tsx';
import { processDataFromClipboard } from '@/components/rules/addRulesBatchModal/processDataFromClipboard.ts';
import type { RuleRowData } from '@/components/rules/rowDataManager/BaseRowDataManager.ts';
import { ModalRowDataManager } from '@/components/rules/rowDataManager/ModalRowDataManager.ts';
import { getAllRowData } from '@/utils/agGrid/agGrid.ts';
import { objectEntries } from '@/utils/libs/entries.ts';

interface AddClauseBatchModalProps {
  defaultColDef: ColDef;
  category: RuleCategory;
  metricsValues: string[];
  rules: RulesModel[];
  importDataFromRulesGrid: (rowDataToAdd: RuleRowData[]) => void;
  externalRowData: RuleRowData[];
}

export const AddClauseBatchModal = NiceModal.create<AddClauseBatchModalProps>(
  ({ defaultColDef, category, metricsValues, rules, importDataFromRulesGrid, externalRowData }) => {
    const [isInvalidGrid, setIsInvalidGrid] = useState(true);

    const analyticalStructures = useAppSelector(
      queryCacheSlice.selectors.analyticalStructureRowData,
    );

    const modalRowDataManager = useMemo<ModalRowDataManager>(
      () => new ModalRowDataManager(category, rules, analyticalStructures),
      [category, rules, analyticalStructures],
    );

    const gridApiRef = useRef<GridApi<RuleRowData> | undefined>();

    const handleGridChange = useCallback(() => {
      const invalidRowDataManagerGrid = modalRowDataManager.isInvalidGrid(
        gridApiRef.current?.getColumnDefs(),
      );
      modalRowDataManager.computeDuplicated(externalRowData);

      gridApiRef.current?.refreshCells({ columns: ['duplicated'] });
      setIsInvalidGrid(invalidRowDataManagerGrid);
    }, [externalRowData, modalRowDataManager]);

    const handleDelete = useCallback(
      (rowId: number) => {
        modalRowDataManager.deleteRowById(rowId);
        const rowData = modalRowDataManager.getRowData();
        gridApiRef.current?.setGridOption('rowData', rowData);
        gridApiRef.current?.refreshCells({ columns: ['duplicated'] });

        if (rowData.length === 0) {
          modalRowDataManager.addEmptyRow();
        }

        handleGridChange();
      },
      [handleGridChange, modalRowDataManager],
    );

    const columnDefs = useMemo<ColDef<RuleRowData>[]>(
      () =>
        getModalColDefs(
          category,
          metricsValues,
          handleDelete,
          modalRowDataManager,
          analyticalStructures,
        ),
      [category, handleDelete, metricsValues, modalRowDataManager, analyticalStructures],
    );

    function onConfirm() {
      const parsedRowData: RuleRowData[] = modalRowDataManager.getRowData().map(row => {
        const parsedRow = objectEntries(row).map(([columnName, val]) => {
          // because the modal return value in string, and we cant strongly type it without super complex code.
          const value = val as string;

          const columnDefinition = rulesModels[category].find(
            columnDef => columnDef.columnName === columnName,
          );
          if (columnDefinition === undefined) {
            return [columnName, value];
          }
          return [columnName, modalRowDataManager.getParsedValue(columnDefinition, value)];
        });

        return Object.fromEntries(parsedRow);
      });

      importDataFromRulesGrid(parsedRowData);
    }

    function onPasteEnd(event: PasteEndEvent) {
      const newRowData = getAllRowData(event.api);

      modalRowDataManager.updateRowData(newRowData);
      modalRowDataManager.computeDuplicated(externalRowData);

      handleGridChange();
    }

    function onCellEditingStopped() {
      const invalidRowDataManagerGrid = modalRowDataManager.isInvalidGrid(
        gridApiRef.current?.getColumnDefs(),
      );
      modalRowDataManager.computeDuplicated(externalRowData);

      gridApiRef.current?.refreshCells({ columns: ['duplicated'] });

      setIsInvalidGrid(invalidRowDataManagerGrid);
    }

    return (
      <BootstrapModal
        size="lg"
        titleId="Rules.AddClauseBatchModal.Title"
        isFullScreen
        footer={
          <CancelConfirmFooter
            onConfirm={onConfirm}
            confirmButtonProps={{
              variant: 'primary',
              disabled: isInvalidGrid,
              component: 'Add batch',
            }}
          />
        }
      >
        <>
          <p>
            After you copied the content from your external tool (CTRL+C), select an empty cell in
            the table and press CTRL+V to paste the content and see its preview.
          </p>
          <AgGridReact<RuleRowData>
            modules={[ClientSideRowModelModule, ClipboardModule, RichSelectModule]}
            className="equity-grid-container mt-3 ag-theme-alpine ag-theme-era"
            rowSelection="multiple"
            rowData={modalRowDataManager.getRowData()}
            gridOptions={{
              defaultColDef,
              columnDefs,
              suppressMenuHide: false,
            }}
            onCellEditingStopped={onCellEditingStopped}
            onGridReady={e => {
              gridApiRef.current = e.api;
            }}
            onPasteEnd={onPasteEnd}
            processDataFromClipboard={processDataFromClipboard}
          />
        </>
      </BootstrapModal>
    );
  },
);
